import React from 'react';
import chuckNorris from '../images/chuckNorris/dude.jpg';
import Chuck from 'react-chuck';

const NotFound = () => (
  <div>
    <br />
    <br />
    <center>
      <h1> Oops, wrong page </h1>
    </center>
    <br />
    <br />
    <center>
      <img alt='' src={chuckNorris} style={{ width: 400, height: 360 }} />
    </center>
    <br />
    <br />
    <div style={{ padding: '40px' }}>
      <center>
        <Chuck />
      </center>
    </div>
    <br />
    <br />
  </div>
);

export default NotFound;
