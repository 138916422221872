import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component, exact = false, path, loggedIn }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) =>
      loggedIn ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const { object, bool, string } = PropTypes;

PrivateRoute.propTypes = {
  exact: bool,
  path: string.isRequired,
  loggedIn: bool.isRequired,
  location: object,
};

export default PrivateRoute;
