import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';
import Group109 from '../../../images/marketing/webHosting/Group109.png';
import Group107 from '../../../images/marketing/webHosting/Group107.png';
import './WebHosting.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1">
      <div
        className="
        d-flex
        flex-column
        align-items-center
        text-center
        position-relative
      "
      >
        <img className="circle-1 img-fluid" src={Shape} alt="circle" />
        <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
        <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
        <h1 className="fw-bold section1-head1">EditPage Web Hosting</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          EditPage has reliable servers that keep your website secure and
          available.
        </p>
        <button
          style={{ borderRadius: '2px' }}
          className="btn-primary demand-btn"
          type="button"
          onClick={() => {
            history.push('/signup');
          }}
          id="button-addon2"
        >
          Start Building
        </button>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6">
          <h1 className="h1">Secure Servers</h1>
          <p>
            Making sure your website runs safely is what we do best. Try us out
            and see how simple it is to launch your secure website. Website
            hosting that works and is safe.
          </p>
          <a href="/webhosting">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src={Group109} alt='' />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="col-md-6 side-image-alignment">
          <img className="img-fluid" src={Group107} alt=''/>
        </div>
        <div className="cart-text col-md-4 offset-md-2">
          <h1 className="h1">Handling Demand</h1>
          <p>
            When your website needs more bandwidth, we autoscale your system to
            meet it. Don't worry about missing out on potential customers. We
            take care of that. Focus on building
          </p>
          <a href="/webhosting">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="blue-section container">
      <div className="blue-section-text col-md-7">
        <h1>Start Crafting your Website</h1>
        <p>
          Get a new experience with the most simple & easy builder on the
          internet right now.
        </p>
      </div>
      <button
        className="btn btn-dark getStarted-btn"
        onClick={() => {
          history.push('/signup');
        }}
        type="submit"
      >
        Start Building
      </button>
    </section>
  </Desktop>
);

export default DesktopResponsive;
