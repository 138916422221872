import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({
  history,
  fields,
  contactSubmit,
  handleChange,
  submitted,
}) => (
  <Mobile>
    <div className="mainWebBuilderHeadingMobile">
      <div className="webBuilderHeadingAlignMobile">
        <h1>
          <span> EditPage Contact </span>
        </h1>
        <div className="webBuilderSubHeadingMobile">
          Send us a message and we will get back to you as soon as we can.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="supportBody">
      <div className="contactAreaMobile">
        <Paper style={{ padding: '20px' }}>
          {submitted ? (
            <div style={{ fontFamily: 'Montserrat', fontSize: '26px' }}>
              <center>
                <ThumbUpAltIcon
                  style={{ fontSize: '250px', color: '#003D66' }}
                />
              </center>
              Thanks we will get back to you as soon as we can.
            </div>
          ) : (
            <div>
              <div className="contactFormTitle"> Contact form </div>
              <form
                noValidate
                autoComplete="none"
                onSubmit={contactSubmit.bind(this)}
              >
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange('firstName', e);
                  }}
                  value={fields['firstName'] || ''}
                  autoComplete="false"
                  InputProps={{ autoComplete: 'none' }}
                />
                &nbsp;
                <br />
                <br />
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange('lastName', e);
                  }}
                  value={fields['lastName'] || ''}
                  InputProps={{ autoComplete: 'none' }}
                />
                <br />
                <br />
                <TextField
                  id="outlined-basic"
                  label="Email Address"
                  variant="outlined"
                  onChange={(e) => {
                    handleChange('email', e);
                  }}
                  value={fields['email'] || ''}
                  InputProps={{
                    autoComplete: 'none',
                    className: 'contactInputEmailMobile',
                  }}
                />
                <br />
                <br />
                <TextField
                  label="Message"
                  multiline
                  rows={6}
                  variant="outlined"
                  onChange={(e) => {
                    handleChange('messageBody', e);
                  }}
                  InputProps={{
                    autoComplete: 'none',
                    className: 'contactInputMessageMobile',
                  }}
                />
                <br />
                <br />
                <Button
                  style={{ backgroundColor: '#DD5E28', color: '#fff' }}
                  variant="contained"
                  size="large"
                  className="ctaButton"
                  type="Submit"
                >
                  Send
                </Button>
              </form>
            </div>
          )}
        </Paper>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
