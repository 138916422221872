export const emailData = [ {
    id: 1, hasAttachment: true, starred: false, username: 'Randy Martin', subject: 'Saas Website', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ', time: '00:48 AM'
  },
  {
    id: 2, hasAttachment: true, starred: false, username: 'Andy Warholl', subject: 'Mobile Apps Marketing', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '03:15 AM'
  },
  {
    id: 3, hasAttachment: false, starred: false, username: 'Steve McManaman', subject: 'New Website', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '05:45 AM'
  },
  {
    id: 4, hasAttachment: true, starred: true, username: 'Ricky Mainakki', subject: 'New Service', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '10:40 AM'
  },
  {
    id: 5, hasAttachment: false, starred: true, username: 'Brian', subject: 'Web Apps Offer', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '05:30 AM'
  },
  {
    id: 6, hasAttachment: true, starred: false, username: 'Randy Martin', subject: 'Saas Website', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ', time: '00:48 AM'
  },
  {
    id: 7, hasAttachment: true, starred: false, username: 'Andy Warholl', subject: 'Mobile Apps Marketing', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '03:15 AM'
  },
  {
    id: 8, hasAttachment: false, starred: false, username: 'Steve McManaman', subject: 'New Website', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '05:45 AM'
  },
  {
    id: 9, hasAttachment: true, starred: true, username: 'Ricky Mainakki', subject: 'New Service', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '10:40 AM'
  },
  {
    id: 10, hasAttachment: false, starred: true, username: 'Brian', subject: 'Web Apps Offer', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '05:30 AM'
  },
  {
    id: 11, hasAttachment: true, starred: false, username: 'Randy Martin', subject: 'Saas Website', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut ', time: '00:48 AM'
  },
  {
    id: 12, hasAttachment: true, starred: false, username: 'Andy Warholl', subject: 'Mobile Apps Marketing', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '03:15 AM'
  },
  {
    id: 13, hasAttachment: false,  starred: false, username: 'Steve McManaman', subject: 'New Website', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '05:45 AM'
  },
  {
    id: 14, hasAttachment: true,  starred: true, username: 'Ricky Mainakki', subject: 'New Service', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '10:40 AM'
  },
  {
    id: 15, hasAttachment: false,  starred: true, username: 'Brian', subject: 'Web Apps Offer', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut', time: '05:30 AM'
  },]