import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './Marketing.css';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';
import Traffic from '../../../images/marketing/marketing/traffic.png';
import MarketingImgFirst from '../../../images/marketing/marketing/marketing-img-first.jpg';
import MarketingImgSecond from '../../../images/marketing/marketing/marketing-img-second.jpg';
import Language from '../../../images/marketing/marketing/language.png';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1">
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <h1 className="fw-bold section1-head1">EditPage Marketing</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          EditPage will help you market your website to the world.
        </p>
        <button
          style={{ borderRadius: '2px' }}
          className="btn-primary demand-btn"
          type="button"
          id="button-addon2"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </button>
      </div>
      <img className="circle-1 img-fluid" src={Shape} alt="circle" />
      <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
      <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
      <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6">
          <div className="img-text">
            <img src={Traffic} alt='' />
            BOOST TRAFFIC FEATURE
          </div>
          <h1 className="h1">Boost Traffic</h1>
          <p>
            We make it easy for people to see who you are on the web. EditPage
            boost traffic feature is simple to use and easy to understand. Grow
            your business fast.
          </p>
          <a href="/marketing">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6 img-wrapper">
          <img
            className="img-fluid"
            style={{ width: '85%' }}
            src={MarketingImgFirst}
            alt=''
          />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="img-wrapper col-md-6 side-image-alignment">
          <img className="img-fluid" src={MarketingImgSecond} alt=''/>
        </div>
        <div className="cart-text col-md-4 offset-md-2">
          <div className="img-text">
            <img src={Language}  alt=''/>
            SOCIAL MEDIA
          </div>

          <h1 className="h1">Social Media</h1>
          <p>
            Connecting with the right audience at the right time can really help
            sales grow. Making sure you run the best social media campaigns.
          </p>
          <a href="/marketing">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="blue-section container">
      <div className="blue-section-text col-md-7">
        <h1>Start Crafting your Website</h1>
        <p>
          Get a new experience with the most simple & easy builder on the
          internet right now.
        </p>
      </div>
      <button
        className="btn btn-dark getStarted-btn"
        onClick={() => {
          history.push('/signup');
        }}
        type="submit"
      >
        Start Building
      </button>
    </section>
  </Desktop>
);

export default DesktopResponsive;
