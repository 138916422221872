import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Circle4 from '../../../images/marketing/featureImages/circle-4.svg';
import Check from '../../../images/marketing/check.svg';
import './Pricing.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1">
      <div
        className="
            d-flex
            flex-column
            align-items-center
            text-center
            position-relative
          "
      >
        <h1 className="fw-bold section1-head1">EditPage Pricing</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          Simple features, simple pricing. Simple.
        </p>
        <button
          style={{ borderRadius: '2px' }}
          onClick={() => {
            history.push('/signup');
          }}
          className="btn-primary demand-btn"
          type="button"
          id="button-addon2"
        >
          Start Building
        </button>
      </div>
      <img  className="circle-1-features img-fluid" src={Shape} alt="circle" />
      <img  className="circle-2 img-fluid" src={Shape2} alt="circle" />
      <img  className="circle-3-homepage img-fluid" src={Shape3} alt="circle" />
      <img  className="circle-4 img-fluid" src={Circle4} alt="circle" />
    </section>
    <section className="container">
      <div className="cards-wrap">
        <div className="sideCard">
          <h4 className="card-head4">eCommerce Package</h4>
          <p className="card-pera"></p>
          <h3 className="card-heading3">$24.99</h3>
          <div className="card-details">
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">
                <b>UNLIMITED</b> Bandwidth
              </span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">
                <b>40GB</b> Storage
              </span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Remove EditPage Ads</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">2 Video Hours</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Free Domain for 1 year</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Email Hosting</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Traffic Booster</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Website Analytics</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Full eCommerce App</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Social Media Icons</span>
            </div>
          </div>
        </div>

        <div className="centerCard">
          <div className="header">
            <h5 className="card-head5">BEST VALUE</h5>
          </div>
          <h4 className="card-head4">Startup Package</h4>
          <p className="card-pera">Best value for startups</p>
          <h3 className="card-heading3">$19.99</h3>
          <div className="card-details">
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">
                <b>UNLIMITED</b> Bandwidth
              </span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">
                <b>20GB</b> Storage
              </span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Remove EditPage Ads</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">2 Video Hours</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Free Domain for 1 year</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Traffic booster</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Website Analytics</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Basic Commerce App</span>
            </div>
          </div>
        </div>

        <div className="sideCard">
          <h4 className="card-head4">Basic Package</h4>
          <p className="card-pera"></p>
          <h3 className="card-heading3">$9.99</h3>
          <div className="card-details">
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">
                <b>UNLIMITED</b> Bandwidth
              </span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">
                <b>5GB</b> Storage
              </span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">Remove EditPage Ads</span>
            </div>
            <div className="d-flex check-wrap">
              <img alt="" className="card-vector" src={Check} />
              <span className="card-vector-span">1 Video Hours</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Desktop>
);

export default DesktopResponsive;
