import React, { Component } from 'react';

import DesktopResponsive from './responsive/About/DesktopResponsive';
import MobileResponsive from './responsive/About/MobileResponsive';

class AboutEditPage extends Component {
  render() {
    return (
      <div>
        <DesktopResponsive />
        <MobileResponsive />
      </div>
    );
  }
}

export default AboutEditPage;
