import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import FeatureEditor from '../../../images/marketing/featureImages/featureEdite.svg';
import FeatureImg1 from '../../../images/marketing/featureImages/featureImg-1.jpg';
import FeatureImg2 from '../../../images/marketing/featureImages/featureImg-2.jpg';
import FeatureImg3 from '../../../images/marketing/featureImages/featureImg-3.jpg';
import FeatureImg4 from '../../../images/marketing/featureImages/featureImg-4.jpg';
import FeatureImg5 from '../../../images/marketing/featureImages/featureImg-5.jpg';
import FeatureImg6 from '../../../images/marketing/featureImages/featureImg-6.jpg';
import Assessment from '../../../images/marketing/featureImages/assessment.svg';
import Domain from '../../../images/marketing/featureImages/domain.svg';
import Storage from '../../../images/marketing/featureImages/storage.svg';
import Email from '../../../images/marketing/featureImages/email.svg';
import Booster from '../../../images/marketing/featureImages/booster.svg';
import './Features.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1 introSection">
      <div className="wrap d-flex flex-column align-items-center text-center position-relative">
        <img className="circle-1-features img-fluid" src={Shape} alt="circle" />
        <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="circle-3-homepage img-fluid" src={Shape3} alt="circle" />
        <h1 className="fw-bold section1-head1">EditPage Features</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          Explore what EditPage offers to setup and publish a beautiful website
          in minutes.
        </p>
        <button
          style={{ borderRadius: '4px' }}
          onClick={() => {
            history.push('/signup');
          }}
          className=" btn-primary demand-btn"
          type="button"
          id="button-addon2"
        >
          Start Building
        </button>
        <div className="btns-wrap">
          <div className="row col-12  justify-content-between">
            <button className="col-3 btn  feature-btn " type="button">
              Intuitive Website Editor
            </button>
            <button className=" col-3 btn feature-btn" type="button">
              Website Analytics
            </button>
            <button className=" col-3 btn feature-btn" type="button">
              Domain Registration{' '}
            </button>
          </div>
          <div className="row col-10 justify-content-between btns-col-wrap">
            <button className="col-3 btn  feature-btn " type="button">
              Website Storage
            </button>
            <button className="col-3 btn feature-btn" type="button">
              Email Hosting
            </button>
            <button className="col-3 btn feature-btn" type="button">
              Boost Traffic{' '}
            </button>
          </div>
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6">
          <div className="feature-corner">
            <img className="f-editor" src={FeatureEditor} alt="Editor" />
            EDITOR
          </div>
          <h1 className="h1">Intuitive Website Editor</h1>
          <p>
            EditPage is your gateway to designing your brand on the internet. In
            just three clicks, you can have a beautiful website that's ready to
            publish.
          </p>
          <a href="/webbuilder">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src={FeatureImg1} alt='' />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="col-md-5 side-image-alignment">
          <img className="img-fluid" src={FeatureImg2} alt='' />
        </div>
        <div className="cart-text col-md-5 offset-md-2">
          <div className="feature-corner">
            <img className="f-editor" src={Assessment} alt="Editor" />
            ANALYTICS
          </div>
          <h1 className="h1">Website Analytics</h1>
          <p>
            EditPage offers the best tools to identify and grow your audience.
            Straightforward insights that require no guess work.
          </p>
          <a href="/">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6 side-image-reverse-alignment">
          <div className="feature-corner">
            <img className="f-editor" src={Domain} alt="Editor" />
            DOMAIN REGISTRATION
          </div>
          <h1 className="h1">Easy Domain Registration</h1>
          <p>
            Need a domain? We've got you covered. Try EditPage's easy domain
            registration to see if your new website name is available.
          </p>
          <a href="/domain">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src={FeatureImg3} alt='' />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="col-md-5 side-image-alignment">
          <img className="img-fluid" src={FeatureImg4} alt='' />
        </div>
        <div className="cart-text col-md-5 offset-md-2">
          <div className="feature-corner">
            <img className="f-editor" src={Storage} alt="Editor" />
            STORAGE
          </div>
          <h1 className="h1">Website Storage</h1>
          <p>
            EditPage gives you all the storage you need with an affordable way
            to upgrade. We know your data is important and we make it easy to
            manage.
          </p>
          <a href="/">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6 side-image-reverse-alignment">
          <div className="feature-corner">
            <img className="f-editor" src={Email} alt="Editor" />
            HOSTING
          </div>
          <h1 className="h1">Email Hosting</h1>
          <p>
            EditPage knows you want to communicate. Safe, secure and a simple
            setup. Signup and try it out.
          </p>
          <a href="/emailhosting">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6 ">
          <img className="img-fluid" src={FeatureImg5} alt='' />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="col-md-5 side-image-alignment">
          <img className="img-fluid" src={FeatureImg6} alt=''/>
        </div>
        <div className="cart-text col-md-5 offset-md-2">
          <div className="feature-corner">
            <img className="f-editor" src={Booster} alt="Editor" />
            BOOSTER
          </div>
          <h1 className="h1">Boost Traffic</h1>
          <p>
            EditPage can drive the right kind of traffic to your website. Quick
            and easy to use marketing materials that get results.
          </p>
          <a href="/marketing">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
  </Desktop>
);

export default DesktopResponsive;
