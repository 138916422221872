import React from 'react';
import Authentication from '../containers/Authentication';
import Footer from '../Footer';
import SideBar from '../SideBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Layout(props) {
  let navBar;
  let footer;

  const adminDashboard=window.location.pathname === '/admin/dashboard';
  if (
    window.location.pathname === '/signup' ||
    window.location.pathname === '/login' ||
    window.location.pathname === '/signupwizard' ||
    window.location.pathname === '/admin/dashboard' ||
    window.location.pathname === '/checkout' ||
    window.location.pathname === '/beta' ||
    window.location.pathname === '/forgotpassword' ||
    window.location.pathname.includes('/updatepassword') ||
    window.location.pathname.includes('/published')
  ) {
    navBar = '';
    footer = '';
  } else if (
    window.location.pathname.includes('/main') ||
    window.location.pathname === '/account' ||
    window.location.pathname === '/dashboard' ||
    window.location.pathname === '/manager'
  ) {
    navBar = <Authentication />;
    footer = '';
  } else {
    navBar = <Authentication />;
    footer = <Footer />;
  }
  return (
    <>
      <Container fluid>
        {props.loggedIn ? (
          <Row style={{flexWrap:'nowrap'}}>
            {!adminDashboard && <Col xs={2} style={{ paddingLeft: '0', paddingRight: '0', maxWidth:'249px'}}>
              <SideBar props={props}/>
            </Col>}
            <Col xs={adminDashboard?12:10} style={{padding:'0'}}>
              {navBar}
              {props.children}
            </Col>
          </Row>
        ) : (
          <div>
            {navBar}
            {props.children}
            {footer}
          </div>
        )}
      </Container>
    </>
  );
}

export default Layout;
