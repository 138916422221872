import React from 'react';
import { connect } from 'react-redux';
import { getPublicWebsite } from '../../actions/websiteActions';

function PublicWebsites(props) {
  const {getPublicWebsite} = props;
  React.useEffect(() => {
    // Get public website based on url name
    // Grab the URL
    let urlFirstPathName = window.location.pathname.substr(
      window.location.pathname.lastIndexOf('/') + 1
    );
    getPublicWebsite({ websiteName: urlFirstPathName });
  }, [getPublicWebsite]);

  React.useEffect(() => {
    // Remove header
    let header = document.getElementsByClassName('navbar');
    let footer = document.getElementsByClassName('page-footer');
    header[0].remove();
    footer[0].remove();
  }, []);

  const filterWebContentforPublicView = () => {
    let filteredContent;
    // We should put any badwords or naughty photos here for sanitization.
    if (props.publicWebsite.html) {
      filteredContent = props.publicWebsite.html
        .replace(/contenteditable=/g, '')
        .replace(/border-radius/, '');
    }
    return filteredContent;
  };

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{ __html: filterWebContentforPublicView() }}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    publicWebsite: state.website.publicWebsite,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPublicWebsite: (data) => {
      dispatch(getPublicWebsite(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicWebsites);
