import { createTheme } from '@material-ui/core/styles';

const COLORS = {
  black: '#000000',
  black1:  '#171725',
  black2: '#2E2C34',
  black3: '#696974',
  black4: '#92929D',
  black5: '#44444F',
  background: '#FAFAFB',
  white: '#FFFFFF',
  red: '#FC5A5A',
  lightRed: '#FFEFEF',
  blue: '#0062FF',
  orange: '#E87443',
  cyan: '#D3E4FF',
  green: '#D3F2FF',
  yellow: '#FFD964',
  purple: '#D9D3FF',
  pink1: '#FFAAAF',
  pink2: '#FFD3EB',
  pink3: '#FFE0D3',
  gray: '#E2E2EA',
  white1: '#F1F1F5',
  transparent: 'transparent',
}

const palette = {
  primary: {
    main: COLORS.blue,
  },
  secondary: {
    main: COLORS.green,
  },
  warning: {
    main: COLORS.orange,
  },
  info: {
    main: COLORS.blue,
  },
  success: {
    main: COLORS.green,
  },
  text: {
    primary: COLORS.black,
    secondary: COLORS.blue,
    disabled: COLORS.cyan,
  },
};

let theme = createTheme({
  colors: COLORS,
  palette,
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: 'orange',
          height: 3,
        },
      },
    },
  },
});

export default theme;
