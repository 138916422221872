import React, { Component } from 'react';

import DesktopResponsive from './responsive/Press/DesktopResponsive';
import MobileResponsive from './responsive/Press/MobileResponsive';

class Press extends Component {
  render() {
    return (
      <div>
        <DesktopResponsive />
        <MobileResponsive />
      </div>
    );
  }
}

export default Press;
