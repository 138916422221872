import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import DesktopResponsive from './responsive/Features/DesktopResponsive';
import MobileResponsive from './responsive/Features/MobileResponsive';

class Features extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Intuitive Features | EditPage</title>
          <meta content="EditPage" name="generator" />
          <link rel="canonical" href="https://www.editpage.com/features" />
        </Helmet>
        <DesktopResponsive history={this.props.history} />
        <MobileResponsive history={this.props.history} />
      </div>
    );
  }
}

export default Features;
