import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Vector from '../../../images/marketing/Vector.svg';
import Editor from '../../../images/marketing/editor.png';
import Check from '../../../images/marketing/check.svg';
import LinkArrow from '../../../images/marketing/linkArrow.svg';
import Template1 from '../../../images/marketing/Template1.png';
import Template2 from '../../../images/marketing/Template2.png';
import Template3 from '../../../images/marketing/Template3.png';
import DomainResults from '../../presentation/DomainResults/DomainResults';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({
  open,
  domainName,
  handleKeyDown,
  handleDomainOpen,
  handleOnChange,
}) => (
  <Desktop>
    {open ? (
      <DomainResults domainName={domainName} />
    ) : (
      <div>
        <section className="section-1-homepage">
          <div
            className="
              d-flex
              flex-column
              align-items-center
              text-center
              position-relative
            "
          >
            <img  className="circle-1 img-fluid" src={Shape} alt="circle" />
            <img  className="circle-2 img-fluid" src={Shape2} alt="circle" />
            <img  className="circle-3-homepage img-fluid" src={Shape3} alt="circle" />
            <h1 className="fw-bold section1-head1">
              We make it simple to <br />
              build a website<span style={{ color: 'orange' }}>.</span>
            </h1>
            <p className="section1-pera" style={{ color: 'gray' }}>
              Create a website in just three clicks. Unique, simple <br />
              and friendly. It's that easy
            </p>
            <div className="d-flex vector-parent">
              <div className="d-flex">
                <img alt="" className="vector-img" src={Vector} />
                <span className="vector-span">No Drag and Drop</span>
              </div>
              <div className="d-flex">
                <img alt="" className="vector-img" src={Vector} />
                <span className="vector-span">Simple Click and Edit</span>
              </div>
              <div className="d-flex">
                <img alt="" className="vector-img" src={Vector} />
                <span className="vector-span">Simple Color Section</span>
              </div>
            </div>

            <div className="search d-flex">
              <div className="input-group">
                <input
                  type="text"
                  onKeyDown={handleKeyDown}
                  onChange={handleOnChange}
                  className="form-control mr-r-5 find-input"
                  placeholder="Find your domain"
                  autoComplete="off"
                  aria-label="Domain Search"
                  aria-describedby="button-addon2"
                  value={domainName.name}
                />
                <button
                  className="btn-primary demand-btn"
                  type="button"
                  onClick={handleDomainOpen}
                  id="button-addon2"
                >
                  Search Domain
                </button>
              </div>
            </div>
            <img  className="bigImg-1 img-fluid" src={Editor} alt="" />
          </div>
        </section>

        <section className="section-2-homepage">
          <div className="container d-flex flex-column align-items-center text-center">
            <h5 className="simple-pr">SIMPLE PRICING</h5>
            <h1 className="section-head">
              We make it easy to get the <br />
              features you want without <br />
              paying alot
              <span style={{ color: 'orange' }}>.</span>
            </h1>
          </div>

          <div className="cards-wrap">
            <div className="sideCard">
              <h4 className="card-head4">eCommerce Package</h4>
              <p className="card-pera"> </p>
              <h3 className="card-heading3">$24.99</h3>
              <div className="card-details">
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    <b>UNLIMITED</b> Bandwidth
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    <b>40GB</b> Storage
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Remove EditPage Ads</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">2 Video Hours</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    Free Domain for 1 year
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Email Hosting</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Traffic Booster</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Website Analytics</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Full eCommerce App </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Social Media Icons </span>
                </div>
              </div>
            </div>

            <div className="centerCard">
              <div className="header">
                <h5 className="card-head5">BEST VALUE</h5>
              </div>
              <h4 className="card-head4">Startup Package</h4>
              <p className="card-pera">Best value for startups</p>
              <h3 className="card-heading3">$19.99</h3>
              <div className="card-details">
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    <b>UNLIMITED</b> Bandwidth
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    <b>20GB</b> Storage
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Remove EditPage Ads</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">2 Video Hours</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    Free Domain for 1 year
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Traffic booster</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Website Analytics</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Basic Commerce App</span>
                </div>
              </div>
            </div>

            <div className="sideCard">
              <h4 className="card-head4">Basic Package</h4>
              <p className="card-pera"> </p>
              <h3 className="card-heading3">$9.99</h3>
              <div className="card-details">
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    <b>UNLIMITED</b> Bandwidth
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">
                    <b>5GB</b> Storage
                  </span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">Remove EditPage Ads</span>
                </div>
                <div className="d-flex check-wrap">
                  <img alt="" className="card-vector" src={Check} />
                  <span className="card-vector-span">1 Video Hours</span>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center see-link-wrap">
            <a className="see-link" href="/pricing">
              See Pricing Page{' '}
            </a>
            <img className="see-arrow" src={LinkArrow} alt="" />
          </div>
          <div className="line"></div>
        </section>

        <section className="section-3">
          <div className="container d-flex flex-column align-items-center text-center">
            <h5 className="simple-pr">TEMPLATE</h5>
            <h1 className="section-head">
              Templates pre-built to <br />
              help you hit the ground running
              <span style={{ color: 'orange' }}>.</span>
            </h1>
          </div>
          <div className="btns-wrap-home">
            <button
              className="btn template-btn template-btn-active"
              type="button"
            >
              Personal Template
            </button>
            <button className="btn template-btn" type="button">
              Business Templates
            </button>
            <button className="btn template-btn" type="button">
              E-Commerce Templates
            </button>
          </div>
          <div className="imgs-wrap row col-10">
            <div className="tamp-image col-md-4">
              <img  className="templateBackgroundColor1 template-img img-fluid" src={Template1} alt="" />
              <span className="temp-span"></span>
            </div>
            <div className="tamp-image col-md-4">
              <img  className="templateBackgroundColor2 template-img img-fluid" src={Template2} alt="" />
              <span className="temp-span"></span>
            </div>
            <div className="tamp-image col-md-4">
              <img  className="templateBackgroundColor3 template-img img-fluid" src={Template3} alt="" />
              <span className="temp-span"></span>
            </div>
          </div>
        </section>
      </div>
    )}
  </Desktop>
);

export default DesktopResponsive;
