import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({
  history,
  classes,
  Accordion,
  AccordionSummary,
  ExpandMoreIcon,
  AccordionDetails,
  Typography,
}) => (
  <Mobile>
    <div className="mainSupportHeadingMobile">
      <div className="supportHeadingAlignMobile">
        <h1>
          <span> EditPage Support </span>
        </h1>
        <div className="supportSubHeadingMobile">EditPage is here to help.</div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">General Questions</div>
        <div className="WTBdescriptionMobile">
          Here are some of the common questions and answers you might have.
        </div>
        <br />
        <div className="FAQList">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do I have to pay to publish my website?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Yes, unfortunately we only have paid product options
                  right now. Soon we will have a free website product.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do I have to buy a domain name to get a
                  website?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> No. We will make your beautiful website publicly
                  viewable with a EditPage url.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Can I cancel my subscriptions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Yes. We make it easy to navigate to your account and
                  cancel any one of your subscriptions.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Product Support</div>
        <div className="WTBdescriptionMobile">
          These are the typical questions we get about our product.
        </div>
        <br />
        <div className="FAQList">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> How do I get the editor to show?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Just click on any item on your webpage and the
                  editor options will show up.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> I boosted my traffic but nothing happened.
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> It can take up to 24 hours for the results to be
                  recorded and then presented.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> How many websites can I have?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> You can have any many websites as you would like. We
                  don't limit the number of pages for each domain name.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="easyDomainRegMobile">
        <div className="easyDomainRegHeadingMobile">Email Support</div>
        <div className="easyDomainRegDescription">
          Basic email questions to help you along.
        </div>
        <br />
        <div className="FAQList">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> How do I know if my email sent?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Send a test email to another one of your email
                  addresses to verify.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do you support IMAP and other client access?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> No. Right now, we only support our client email
                  system.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> How secure is the email system?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Our system is very secure and accepted by most
                  common standards.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Domain Name Support</div>
        <div className="WTBdescriptionMobile">
          Questions about your domain name on EditPage
        </div>
        <br />
        <div className="FAQList">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Will I be able to transfer my domain name?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> No. Currently, we only allow domains to stay on our
                  system.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do you really offer some domain names for
                  free?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Yes. We offer a 1 year free purchase with some
                  select website packages.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do you support most common TLDs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Yes. You can find just about any of the most popular
                  domain names available on our site.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Boost Traffic Support</div>
        <div className="WTBdescriptionMobile">
          How our traffic system works and questions.
        </div>
        <br />
        <div className="FAQList">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> If I purchase more traffic will I get more
                  customers?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Sometimes. It's not a direct guarantee that if you
                  boost your traffic you will get more customers.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do you offer social media traffic?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> Yes. You will be able to get more traffic by our
                  systems advertising on social media.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <b>Question:</b> Do you offer refunds on traffic boosts?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>A:</b> No. We can not offer any refunds because of the
                  nature of advertising.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Contact us</div>
        <div className="WTBdescriptionMobile">
          Please send us a message if you need more help.
        </div>
        <br />
        <div className="FAQList">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Contact us form
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.heading}>
                  <b>
                    <u>
                      <a href="https://www.editpage.com/contact">Contact us</a>
                    </u>
                  </b>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
