import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './About.css';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1">
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <h1 className="fw-bold section1-head1">About EditPage</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          Website Creation Made Simple
        </p>
      </div>
      <img className="circle-1 img-fluid" src={Shape} alt="circle" />
      <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
      <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
      <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="aboutBodyText">
          EditPage has developed easy to use tools to allow you to create your
          Website without
          <br /> having to learn code or wasting time figuring out complex web
          builders.
          <br />
          <br />
          At EditPage our mission is to provide the easiest way to build and
          deploy a website on
          <br /> the web.
          <br />
          <br />
          EditPage has focused on building intuitive features that help you
          focus on your message with the intention for everyone being able to
          build their website easily. Our development team at EditPage wanted to
          build a website builder that any person at any skill level could
          establish their web presence.
          <br />
        </div>
      </div>
    </section>
  </Desktop>
);

export default DesktopResponsive;
