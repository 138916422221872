import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import DesktopResponsive from '../responsive/Pricing/DesktopResponsive';
import MobileResponsive from '../responsive/Pricing/MobileResponsive';

class Pricing extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Simple Pricing | EditPage</title>
          <link rel="canonical" href="https://www.editpage.com/pricing" />
        </Helmet>
        <DesktopResponsive history={this.props.history} />
        <MobileResponsive history={this.props.history} />
      </div>
    );
  }
}

export default Pricing;
