import constants from '../constants/actionTypes';

var initialState = {
  alerts: { typeAlert: '', message: '' },
};

export default (state = initialState, action) => {
  var updated = Object.assign({}, state);

  switch (action.type) {
    case constants.ALERT_SIGNAL:
      updated['alerts']['typeAlert'] = action.typeAlert;
      updated['alerts']['message'] = action.message;
      return updated;

    default:
      return state;
  }
};
