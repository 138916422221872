import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import jobsImage from '../../../images/jobsImage.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainFeaturesHeadingMobile">
      <div className="featuresHeadingAlignMobile">
        <h1>
          <span> Jobs At EditPage </span>
        </h1>
        <div className="featuresSubHeadingMobile">
          Let us know if you're interested in working with us.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <Container>
          <Row>
            <Col>
              <div className="jobsDesc">
                At EditPage we pride ourselves on providing an incredible work
                environment. We love to hire talent that brings unique
                prospectives and value to EditPage. We want to have a work
                environment where people feel their idea's are heard and are
                excited about being involved in the growth of EditPage. We
                believe in investing in our team and in their growth.
              </div>
            </Col>
            <Col>
              <br />
              <br />
              <img width="261" height="242" src={jobsImage} alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="WTBHeadingMobile">Current Jobs at EditPage</div>
      <div className="WTBdescriptionMobile">
        No job openings. Check back again.
      </div>
      <br />
    </div>
  </Mobile>
);

export default MobileResponsive;
