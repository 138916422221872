import React from 'react';
import DesktopResponsive from './responsive/DomainRegistration/DesktopResponsive';
import MobileResponsive from './responsive/DomainRegistration/MobileResponsive';

function DomainRegistration(props) {
  const [open, setOpen] = React.useState(false);
  const [domainName, setDomainName] = React.useState({ name: '' });
  const [error, setError] = React.useState({ type: '', message: '' });
  const { loggedIn } = props;
  const { history } = props;

  const detectCurrentDomainName = React.useCallback(() => {
    let currentDomain = window.location.hostname;
    console.log('This is the domain: ', window.location.href);
    var referrer = window.location.href;
    console.log('referrer url', referrer);
    if (currentDomain.includes('blockpire')) {
      props.history.push('/gemstatehearing');
      console.log('Current domain: ', currentDomain);
    }
  }, [props.history]);
  

  React.useEffect(() => {
    detectCurrentDomainName();
    if (loggedIn) {
      history.push('/dashboard');
    }
  }, [loggedIn, detectCurrentDomainName, history]);

  const handleDomainClose = () => {
    setOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleDomainOpen();
    }
  };

  // const checkIfValidDomain = (domain) => {
  //   // Check to see if it has a .something
  //   if (
  //     domain.includes('.com') ||
  //     domain.includes('.net') ||
  //     domain.includes('.co') ||
  //     domain.includes('.xyz') ||
  //     domain.includes('.me') ||
  //     domain.includes('.io')
  //   ) {
  //     return true;
  //   }
  // };

  const handleDomainOpen = (event) => {
    if (domainName.name && domainName.name.length < 64) {
      setOpen(true);
    } else {
      setError({
        type: 'valid-domain',
        message: 'Please enter a valid domain name',
      });
    }
  };

  const handleOnChange = (evt) => {
    setDomainName({ name: evt.target.value });
  };



  return (
    <div>
      <DesktopResponsive
        open={open}
        domainName={domainName}
        error={error}
        handleKeyDown={handleKeyDown}
        handleDomainClose={handleDomainClose}
        handleDomainOpen={handleDomainOpen}
        handleOnChange={handleOnChange}
      />
      <MobileResponsive />
    </div>
  );
}

export default DomainRegistration;
