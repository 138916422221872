import constants from '../constants/actionTypes';

var initialState = {
  website: { isLoaded: false },
  publicWebsite: { isLoaded: false },
  userWebsites: [],
  userWebsitesLoaded: { isLoaded: false },
  websites: [],
};

export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.GET_WEBSITES:
      updated['websites'] = action.results;
      return updated;

    case constants.GET_WEBSITE:
      updated['website'] = action.results;
      updated['website']['isLoaded'] = true;
      return updated;

    case constants.GET_PUBLIC_WEBSITE:
      updated['publicWebsite'] = action.results;
      updated['publicWebsite']['isLoaded'] = true;
      return updated;

    case constants.GET_USER_WEBSITES:
      updated['userWebsites'] = action.results;
      updated['userWebsitesLoaded']['isLoaded'] = true;
      return updated;

    case constants.CREATE_WEBSITE:
      updated['website'] = action.results;
      updated['website']['isLoaded'] = true;
      return updated;

    default:
      return state;
  }
};
