import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './Support.css';
import '../Features/Features.css';
import '../Styles.css';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Circle4 from '../../../images/marketing/featureImages/circle-4.svg';
import LinkArrow from '../../../images/marketing/north_east.svg';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({
  history,
  classes,
  Accordion,
  AccordionSummary,
  ExpandMoreIcon,
  AccordionDetails,
  Typography,
}) => (
  <Desktop>
    <section className="support-section-1">
      <div className="wrap d-flex flex-column align-items-center text-center position-relative">
        <img className="support-circle-1 img-fluid" src={Shape} alt="circle" />
        <img className="support-circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="support-circle-3 img-fluid" src={Shape3} alt="circle" />
        <img
          className="support-circle-4 img-fluid"
          src={Circle4}
          alt="circle"
        />
        <h1 className="fw-bold section1-head1">Support</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          {' '}
          EditPage is here to help. Just let us know
        </p>
        <button
          style={{ borderRadius: '4px' }}
          className=" btn-primary demand-btn"
          onClick={() => {
            history.push('/signup');
          }}
          type="button"
          id="button-addon2"
        >
          Start Building
        </button>
        <div className="btns-wrap">
          <div className="support-row justify-content-between">
            <button className="support-btn-1 btn  feature-btn " type="button">
              <a href="#generalQuestions">General Questions </a>
            </button>
            <button className="support-btn-1  btn feature-btn" type="button">
              <a href="#productSupport">Product Support</a>
            </button>
            <button className="support-btn-1  btn feature-btn" type="button">
              <a href="#emailSupport">Email Support</a>{' '}
            </button>
          </div>
          <div className="support-row justify-content-between btns-col-wrap">
            <button className="support-btn-2 btn  feature-btn " type="button">
              <a href="#domainSupport">Domain Name Support</a>
            </button>
            <button className="support-btn-2 btn feature-btn" type="button">
              <a href="#trafficSupport">Boost Traffic Support</a>
            </button>
            <button className="support-btn-2 btn feature-btn" type="button">
              <a href="#contact">Contact Us</a>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section className="support-section">
      <div className="wrapper">
        <h2 id="generalQuestions" className="wrap-h2">
          General Questions
        </h2>
        <p className="wrap-pera">
          Here are some of the common questions and answers you might have.
        </p>
        <div id="main">
          <div className="accordion" id="faq">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="card-header"
                id="faqhead1"
              >
                <Typography
                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq1"
                  aria-expanded="true"
                  aria-controls="faq1"
                >
                  Do I have to pay to publish my website?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq1"
                className="collapse show"
                aria-labelledby="faqhead1"
                data-parent="#faq"
              >
                <Typography className="card-body">
                  Yes, unfortunately we only have paid product options right
                  now. Soon we will have a free website product.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                className="card-header"
                id="faqhead2"
              >
                <Typography
                  href="#"
                  className="btn btn-header-link collapsed"
                >
                  Do I have to buy a domain name to get a website?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                className="collapse show"
                id="faq2"
                aria-labelledby="faqhead2"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  No. We will make your beautiful website publicly viewable with
                  a EditPage url.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                className="card-header"
                id="faqhead3"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq3"
                  aria-expanded="true"
                  aria-controls="faq3"
                >
                  Can I cancel my subscriptions?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq3"
                className="collapse show"
                aria-labelledby="faqhead3"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Yes. We make it easy to navigate to your account and cancel
                  any one of your subscriptions.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <h2 id="productSupport" className="wrap-h2">
          Product Support
        </h2>
        <p className="wrap-pera">
          These are the typical questions we get about our product.
        </p>
        <div id="main">
          <div className="accordion" id="faq">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                className="card-header"
                id="faqhead1"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq1"
                  aria-expanded="true"
                  aria-controls="faq1"
                >
                  How do I get the editor to show?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq1"
                className="collapse show"
                aria-labelledby="faqhead1"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Just click on any item on your webpage and the editor options
                  will show up.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                className="card-header"
                id="faqhead2"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq2"
                  aria-expanded="true"
                  aria-controls="faq2"
                >
                  I boosted my traffic but nothing happened.
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq2"
                className="collapse show"
                aria-labelledby="faqhead2"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  It can take up to 24 hours for the results to be recorded and
                  then presented.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                className="card-header"
                id="faqhead3"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq3"
                  aria-expanded="true"
                  aria-controls="faq3"
                >
                  How many websites can I have?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq3"
                className="collapse show"
                aria-labelledby="faqhead3"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  You can have any many websites as you would like. We don't
                  limit the number of pages for each domain name.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <h2 id="emailSupport" className="wrap-h2">
          Email Support
        </h2>
        <p className="wrap-pera">Basic email questions to help you along.</p>
        <div id="main">
          <div className="accordion" id="faq">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"

                className="card-header"
                id="faqhead1"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq1"
                  aria-expanded="true"
                  aria-controls="faq1"
                >
                  How do I know if my email sent?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq1"
                className="collapse show"
                aria-labelledby="faqhead1"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Send a test email to another one of your email addresses to
                  verify.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"

                className="card-header"
                id="faqhead2"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq2"
                  aria-expanded="true"
                  aria-controls="faq2"
                >
                  Do you support IMAP and other client access?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq2"
                className="collapse show"
                aria-labelledby="faqhead2"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  No. Right now, we only support our client email system.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"

                className="card-header"
                id="faqhead3"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq3"
                  aria-expanded="true"
                  aria-controls="faq3"
                >
                  How secure is the email system?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq3"
                className="collapse show"
                aria-labelledby="faqhead3"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Our system is very secure and accepted by most common
                  standards.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <h2 id="domainSupport" className="wrap-h2">
          Domain Name Support
        </h2>
        <p className="wrap-pera">
          Questions about your domain name on EditPage
        </p>
        <div id="main">
          <div className="accordion" id="faq">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"

                className="card-header"
                id="faqhead1"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq1"
                  aria-expanded="true"
                  aria-controls="faq1"
                >
                  Will I be able to transfer my domain name?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq1"
                className="collapse show"
                aria-labelledby="faqhead1"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  No. Currently, we only allow domains to stay on our system.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"

                className="card-header"
                id="faqhead2"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq2"
                  aria-expanded="true"
                  aria-controls="faq2"
                >
                  Do you really offer some domain names for free?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq2"
                className="collapse show"
                aria-labelledby="faqhead2"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Yes. We offer a 1 year free purchase with some select website
                  packages.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"

                className="card-header"
                id="faqhead3"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq3"
                  aria-expanded="true"
                  aria-controls="faq3"
                >
                  Do you support most common TLDs?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq3"
                className="collapse show"
                aria-labelledby="faqhead3"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Yes. You can find just about any of the most popular domain
                  names available on our site.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <h2 id="trafficSupport" className="wrap-h2">
          Boost Traffic Support
        </h2>
        <p className="wrap-pera">How our traffic system works and questions.</p>
        <div id="main">
          <div className="accordion" id="faq">
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"

                className="card-header"
                id="faqhead1"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq1"
                  aria-expanded="true"
                  aria-controls="faq1"
                >
                  If I purchase more traffic will I get more customers?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq1"
                className="collapse show"
                aria-labelledby="faqhead1"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Sometimes. It's not a direct guarantee that if you boost your
                  traffic you will get more customers.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"

                className="card-header"
                id="faqhead2"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq2"
                  aria-expanded="true"
                  aria-controls="faq2"
                >
                  {' '}
                  Do you offer social media traffic?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq2"
                className="collapse show"
                aria-labelledby="faqhead2"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  Yes. You will be able to get more traffic by our systems
                  advertising on social media.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="card">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"

                className="card-header"
                id="faqhead3"
              >
                <Typography

                  href="#"
                  className="btn btn-header-link collapsed"
                  data-toggle="collapse"
                  data-target="#faq3"
                  aria-expanded="true"
                  aria-controls="faq3"
                >
                  Do you offer refunds on traffic boosts?
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                id="faq3"
                className="collapse show"
                aria-labelledby="faqhead3"
                data-parent="#faq"
              >
                <Typography  className="card-body">
                  No. We can not offer any refunds because of the nature of
                  advertising.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="contect-Us">
            <h2 id="contact">
              Contact us
            </h2>
            <p>Please send us a message if you need more help.</p>
            <div className="contect-link">
              <a href="./contact">
                Contact us Form <img src={LinkArrow} alt=""></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Desktop>
);

export default DesktopResponsive;
