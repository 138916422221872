import React from 'react';
import Form from 'react-bootstrap/Form';
import { useMediaQuery } from 'react-responsive';
import Link from '@material-ui/core/Link';

import logo from '../../../images/logo.png';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({
  login,
  authError,
  authErrorMessage,
  submitted,
  email,
  updateDetails,
}) => (
  <Desktop>
    <div className="loginBackground">
      <div className="logoWiz spacerFromLogo">
        <img width="180" height="74" src={logo} alt="" />
      </div>
      <div className="forgotPassContainer">
        <h3
          style={{
            fontFamily: 'Montserrat',
            fontWeight: '600',
            color: '#00003B',
          }}
        >
          Login
        </h3>
        <Form onSubmit={login}>
          <div className="loginErrorMessage">
            {authError ? authErrorMessage : ''}
          </div>
          <Form.Group
            className={'' + (submitted && !email ? ' has-error' : '')}
          >
            <Form.Label><b>Email address</b></Form.Label>
            <Form.Control
              onChange={updateDetails.bind(this)}
              id="email"
              type="email"
              placeholder="Enter email"
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label><b>Password</b></Form.Label>
            <Form.Control
              onChange={updateDetails.bind(this)}
              id="password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group className="forgotPassFooter">
              <Link href="/forgotpassword">
                {' '}
                <b>Forgot password</b>{' '}
              </Link>
          <button
            className="forgotPassBtn"
            type="submit"
          >
            Login
          </button>
          </Form.Group>
        </Form>
      </div>
    </div>
  </Desktop>
);

export default DesktopResponsive;
