import React from 'react';
import { connect } from 'react-redux';
import { toggleCart } from "../actions/cartActions"
import logoWhite from '../images/logo-white.png';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from './responsive/Menu/Menu';
import MenuItem from './responsive/Menu/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { history } from '../helpers/history';
import NavBarNotifications from './navbar/NavBarNotification/NavBarNotification';
import CartIcon from "../images/icons/cart.svg";
//import ProfileMenu from './ProfileMenu';

const notifications = [
  {
    id: 1,
    title: 'New message received',
    body: 'You have a new message from John Doe.',
    recent: true,
  },
  {
    id: 2,
    title: 'Payment received',
    body: 'You have received a payment of $100 from Jane Smith.',
    recent: true,
  },
  {
    id: 3,
    title: 'Upcoming event',
    body: 'Reminder: You have an event scheduled for tomorrow at 2:00 PM.',
    recent: true,
  },
  {
    id: 4,
    title: 'New friend request',
    body: 'You have a new friend request from Bob Johnson.',
    recent: false,
  },
  {
    id: 5,
    title: 'New follower',
    body: 'You have a new follower on your social media account.',
    recent: false,
  },
  {
    id: 6,
    title: 'Security alert',
    body: 'There was an attempt to log in to your account from an unrecognized device.',
    recent: false,
  },
  {
    id: 7,
    title: 'Product update',
    body: 'A new version of our product is now available. Click here to learn more.',
    recent: false,
  },
];

const emails = [
  {
    id: 1,
    subject: 'Meeting Request',
    body: 'Hello, I would like to request a meeting with you to discuss the new project.',
    sender: 'John Doe',
    date: '2022-03-19T10:30:00Z',
  },
  {
    id: 2,
    subject: 'Action Required: Account Verification',
    body: 'Please verify your account by clicking the following link: https://example.com/verify',
    sender: 'Example.com',
    date: '2022-03-18T08:45:00Z',
  },
  {
    id: 3,
    subject: 'Important Update: New Feature Release',
    body: 'We are excited to announce the release of our new feature. Click here to learn more: https://example.com/features',
    sender: 'Example.com',
    date: '2022-03-17T14:20:00Z',
  },
  {
    id: 4,
    subject: 'Job Application Status',
    body: 'Thank you for applying for the Software Engineer position at our company. We will be in touch with you shortly regarding your application status.',
    sender: 'HR Department',
    date: '2022-03-15T09:10:00Z',
  },
  {
    id: 5,
    subject: 'Invitation to Webinar',
    body: 'You are invited to our upcoming webinar on "Best Practices for Email Marketing". Click here to register: https://example.com/webinar',
    sender: 'Marketing Department',
    date: '2022-03-13T11:50:00Z',
  },
];

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 991 });
  return isMobile ? children : null;
};

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 991 });
  return isDesktop ? children : null;
};

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  customBadge: {
    backgroundColor: '#e77343',
    color: 'white',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const NavStyledBlack = ({
  navFeaturesLinkActive,
  navPricingLinkActive,
  navSupportLinkActive,
}) => {
  return (
    <ul
      className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll"
      style={{ '--bs-scroll-height': '100px' }}
    >
      <li className="nav-item">
        <a
          className={
            navFeaturesLinkActive ? 'nav-link active-menu' : 'nav-link'
          }
          aria-current="page"
          href="/features"
        >
          Features
        </a>
      </li>
      <li className="nav-item">
        <a
          className={navPricingLinkActive ? 'nav-link active-menu' : 'nav-link'}
          href="/pricing"
        >
          Pricing
        </a>
      </li>
      <li className="nav-item">
        <a
          className={navSupportLinkActive ? 'nav-link active-menu' : 'nav-link'}
          href="/support"
          tabIndex="-1"
          aria-disabled="true"
        >
          Support
        </a>
      </li>
    </ul>
  );
};

function NewNavbar(props) {
  const classes = useStyles();
  const [navFeaturesLinkActive, setNavFeaturesLinkActive] =
    React.useState(false);
  const [navPricingLinkActive, setNavPricingLinkActive] = React.useState(false);
  const [navSupportLinkActive, setSupportLinkActive] = React.useState(false);
  //eslint-disable-next-line
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setOpenMobileMenu(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  // const logout = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  //   props.logout();
  //   history.push('/');
  // };

  const handleLinkClick = (val) => {
    if (val === 'Home') {
      setOpenMobileMenu(false);
      history.push('/');
    } else if (val === 'Features') {
      setOpenMobileMenu(false);
      history.push('/features');
    } else if (val === 'Pricing') {
      setOpenMobileMenu(false);
      history.push('/pricing');
    } else if (val === 'Support') {
      setOpenMobileMenu(false);
      history.push('/support');
    } else if (val === 'Login') {
      setOpenMobileMenu(false);
      history.push('/login');
    } else if (val === 'Signup') {
      setOpenMobileMenu(false);
      history.push('/signup');
    }
  };

  React.useEffect(() => {
    // Set links if active
    if (
      document.URL.includes('features') ||
      document.URL.includes('domain') ||
      document.URL.includes('webbuilder') ||
      document.URL.includes('webhosting') ||
      document.URL.includes('emailhosting') ||
      document.URL.includes('marketing') ||
      document.URL.includes('onlinestore')
    ) {
      setNavFeaturesLinkActive(true);
    } else if (document.URL.includes('pricing')) {
      setNavPricingLinkActive(true);
    } else if (document.URL.includes('support')) {
      setSupportLinkActive(true);
    }
  }, []);

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <NavBarNotifications items={emails} icon={MailIcon} type="email" />
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <NavBarNotifications
          items={notifications}
          icon={NotificationsIcon}
          type="notification"
        />
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenu = [
    'Home',
    'Features',
    'Pricing',
    'Support',
    'Login',
    'Signup',
  ];
  const menuItems = mobileMenu.map((val, index) => {
    return (
      <MenuItem
        key={index}
        delay={`${index * 0.1}s`}
        onClick={() => {
          handleLinkClick(val);
        }}
      >
        {val}
      </MenuItem>
    );
  });

  return (
    <div>
      {props.loggedIn ? (
        <div className={classes.grow}>
          <AppBar
            style={{ background: 'white' }}
            position="static"
            elevation={0}
          >
            <Toolbar>
              <div className={classes.grow} />
              <div className={classes.sectionDesktop}>
                {/*<NavBarNotifications
                  items={emails}
                  icon={MailIcon}
                  type="email"
                />*/}
                <NavBarNotifications
                  items={notifications}
                  icon={NotificationsIcon}
                  type="notification"
                />
                {/*<ProfileMenu user={props.currentUser} logout={props.logout}/>*/}
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        /* NOT LOGGED IN */
        <div>
          <Mobile>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <a className="navbar-brand" href="/">
                <img width="132" height="57" src={logoWhite} alt="" />
              </a>
              <Menu open={openMobileMenu}>{menuItems}</Menu>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarScroll"
                aria-controls="navbarScroll"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={handleMobileMenuOpen}
              >
                <span className="navbar-toggler-icon" />
              </button>

              {renderMobileMenu}
            </nav>
          </Mobile>
          <Desktop>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container-fluid">
                <a className="navbar-brand" href="/">
                  <img width="172" height="77" src={logoWhite} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarScroll"
                  aria-controls="navbarScroll"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className="collapse navbar-collapse justify-content-center"
                  id="navbarScroll"
                >
                  <NavStyledBlack
                    navFeaturesLinkActive={navFeaturesLinkActive}
                    navPricingLinkActive={navPricingLinkActive}
                    navSupportLinkActive={navSupportLinkActive}
                  />
                  <form className="d-flex">
                    <button
                      className="btn btn-outline-success login-btn"
                      onClick={() => {
                        handleLinkClick('Login');
                      }}
                      type="submit"
                    >
                      Login
                    </button>
                    <button
                      className="btn btn-dark getStarted-btn"
                      onClick={() => history.push('/signup')}
                      type="submit"
                    >
                      Get Started
                    </button>
                    {props.isDomainResultsOpen &&
                      <div className="cart-icon-wrapper" onClick={() => props.toggleCart()}>
                        <div className={`cart-btn ${props.isCartFilled && "filled"}`}>
                          <img src={CartIcon} alt="" />
                        </div>
                        {props.totalAmount > 0 && <span className="cart-price">$ {props.totalAmount.toFixed(2)}</span>}
                      </div>
                    }
                  </form>
                </div>
              </div>
            </nav>
          </Desktop>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isDomainResultsOpen: state.domain.isDomainResultsOpen,
    isCartOpen: state.cart.isCartOpen,
    isCartFilled: state.cart.isCartFilled,
    totalAmount: state.cart.totalAmount
  };
};

export default connect(mapStateToProps, { toggleCart })(NewNavbar);