import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';
import Email from '../../../images/marketing/emailHosting/email.png';
import EmailFirst from '../../../images/marketing/emailHosting/emailFirst.jpg';
import EmailSecond from '../../../images/marketing/emailHosting/emailSecond.jpg';
import PrivacyTip from '../../../images/marketing/emailHosting/privacyTip.png';
import './EmailHosting.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1">
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <img className="circle-1 img-fluid" src={Shape} alt="circle" />
        <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
        <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
        <h1 className="fw-bold section1-head1">EditPage Email Hosting</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          EditPage offers email as an option together with your website.
        </p>
        <button
          style={{ borderRadius: '2px' }}
          className="btn-primary demand-btn"
          type="button"
          id="button-addon2"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </button>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6">
          <div className="img-text">
            <img src={Email} alt='' />
            EMAIL FEATURE
          </div>
          <h1 className="h1">Easy Communication</h1>
          <p>
            Make sure you don't miss an important opportunity. Our email system
            helps you get your business started all in one place. Just a couple
            of clicks and your email is setup.
          </p>
          <a href="/emailhosting">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6 img-wrapper">
          <img className="img-fluid" style={{ width: '85%' }} src={EmailFirst} alt='' />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="img-wrapper col-md-6 side-image-alignment">
          <img className="img-fluid" src={EmailSecond} alt=''/>
        </div>
        <div className="cart-text col-md-4 offset-md-2">
          <div className="img-text">
            <img src={PrivacyTip} alt='' />
            PRIVACY PROTECTION
          </div>

          <h1 className="h1">Simple Setup</h1>
          <p>
            We make sure you aren't bothered by unrelated emails. We build our
            email system from the ground up so you can trust what we offer.
          </p>
          <a href="/emailhosting">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="blue-section container">
      <div className="blue-section-text col-md-7">
        <h1>Start Crafting your Website</h1>
        <p>
          Get a new experience with the most simple & easy builder on the
          internet right now.
        </p>
      </div>
      <button
        className="btn btn-dark getStarted-btn"
        onClick={() => {
          history.push('/signup');
        }}
        type="submit"
      >
        Start Building
      </button>
    </section>
  </Desktop>
);

export default DesktopResponsive;
