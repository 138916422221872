import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './Contact.css';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Circle4 from '../../../images/marketing/featureImages/circle-4.svg';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({
  contactSubmit
}) => (
  <Desktop>
    <section className="section-1 ">
      <div className="container">
        <img className="circle-1 img-fluid" src={Shape} alt="circle" />
        <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
        <img className="circle-4 img-fluid" src={Circle4} alt="circle" />
        <div className="card my-card">
          <div className="my-card-header">
            <h1>EditPage Contact</h1>
            <p>
              Send us a message and we will get back to you as soon as we can.
            </p>
          </div>
          <form
            name="contactform"
            noValidate
            autoComplete="none"
            onSubmit={contactSubmit.bind(this)}
          >
            <div className="form-row">
              <div className="form-group col-md-6 ">
                <label htmlFor="inputEmail4">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  placeholder="First Name"
                />
              </div>
              <div className="form-group col-md-6 ">
                <label htmlFor="inputPassword4">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputPassword4"
                  placeholder="Last Name"
                />
              </div>
            </div>
            <div className="form-group margin-top-10">
              <label htmlFor="inputAddress">Email</label>
              <input
                type="email"
                className="form-control"
                id="inputAddress"
                placeholder="Your email"
              />
            </div>
            <div className="form-group margin-top-20">
              <label htmlFor="inputAddress2">Message</label>
              <textarea
                type="text"
                className="form-control"
                placeholder="Your message"
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary ctaButton">
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  </Desktop>
);

export default DesktopResponsive;
