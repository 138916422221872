import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonMaterial from '@material-ui/core/Button';
import { useMediaQuery } from 'react-responsive';


import logo from '../../../images/logo.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({
  login,
  authError,
  authErrorMessage,
  submitted,
  email,
  updateDetails,
  history,
}) => (
  <Mobile>
    <div className="loginBackgroundMobile">
      <div className="logoWiz spacerFromLogo">
        <img width="236" height="94" src={logo} alt="" />
      </div>
      <div className="loginFormMobile">
        <h3>Login</h3>
        <Form onSubmit={login}>
          <div className="loginErrorMessage">
            {authError ? authErrorMessage : ''}
          </div>
          <Form.Group
            className={'' + (submitted && !email ? ' has-error' : '')}
          >
            <Form.Label>Email address</Form.Label>
            <Form.Control
              onChange={(e) => {
                updateDetails(e);
              }}
              id="email"
              type="email"
              placeholder="Enter email"
            />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Password</Form.Label>
            <Form.Control
              onChange={(e) => {
                updateDetails(e);
              }}
              id="password"
              type="password"
              placeholder="Password"
            />
          </Form.Group>
          <Form.Group controlId="formBasicChecbox">
            <Form.Check type="checkbox" label="Remember" />
          </Form.Group>
          <div className="row">
            <div className="col-xs-6 text-right">
              <ButtonMaterial
                style={{
                  width: '117px',
                  height: '63px',
                  backgroundColor: '#003D66',
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Login
              </ButtonMaterial>
            </div>
            <div className="col-xs-6 text-left">
              <Button
                style={{ width: '140px', height: '63px', marginLeft: '10px' }}
                variant="secondary"
                onClick={() => {
                  history.push('/forgotpassword');
                }}
              >
                Forgot password
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
