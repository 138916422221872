import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@material-ui/core/Button';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainPricingHeadingMobile">
      <div className="pricingHeadingAlignMobile">
        <h1>
          <span> EditPage Pricing </span>
        </h1>
        <div className="pricingSubHeadingMobile">
          Simple features, simple pricing. Simple.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="bodyArea">
      <Container>
        <Row className="pricingPlans">
          <Col>
            <div className="featuredPlanTopMobile">
              <div className="headUp">BEST VALUE</div>
              <div className="startupPlan">
                <div className="planHeading">Startup Package</div>
                <div className="planSubheading">Best value for startups</div>
                <div className="planPriceMobile">
                  <sup>$</sup>19<sup>.99/</sup>
                  <div className="planPriceMonth">month</div>
                </div>
                <div className="lineBar"></div>
                <div className="planFeaturesMobile">
                  <p>
                    <strong>UNLIMITED</strong> Bandwidth
                  </p>
                  <p>
                    <strong>20GB</strong> Storage
                  </p>
                  <p>Remove EditPage Ads</p>
                  <p>2 Video Hours</p>
                  <p>Free Domain for 1 year</p>
                  <p>Traffic Booster</p>
                  <p>Website Analytics</p>
                  <p>Basic eCommerce App</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pricingPlans">
          <Col>
            <div className="basicPlan">
              <div className="planHeading">Basic Package</div>
              <div className="planSubheading">Enough to get started</div>
              <div className="planPriceMobile">
                <sup>$</sup>9<sup>.99/</sup>
                <div className="planPriceMonth">month</div>
              </div>
              <div className="lineBar"></div>
              <div className="planFeaturesMobile">
                <p>
                  <strong>UNLIMITED</strong> Bandwidth
                </p>
                <p>
                  <strong>5GB</strong> Storage
                </p>
                <p>Remove EditPage Ads</p>
                <p>1 Video Hours</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pricingPlans">
          <Col>
            <div className="eCommercePlan">
              <div className="planHeading">eCommerce Package</div>
              <div className="planSubheading">Everything you need</div>
              <div className="planPriceMobile">
                <sup>$</sup>24<sup>.99/</sup>
                <div className="planPriceMonth">month</div>
              </div>
              <div className="lineBar"></div>
              <div className="planFeaturesMobile">
                <p>
                  <strong>UNLIMITED</strong> Bandwidth
                </p>
                <p>
                  <strong>40GB</strong> Storage
                </p>
                <p>Remove EditPage Ads</p>
                <p>2 Video Hours</p>
                <p>Free Domain for 1 year</p>
                <p>Email Hosting</p>
                <p>Traffic Booster</p>
                <p>Website Analytics</p>
                <p>Full eCommerce App</p>
                <p>Social Media Icons</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </Mobile>
);

export default MobileResponsive;
