export const validateInputs = (formInputs, setFormInputs) => {
  let newErrorsInput = {};
  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (formInputs.firstName.trim() === '') newErrorsInput.firstName = true;

  if (formInputs.lastName.trim() === '') newErrorsInput.lastName = true;

  if (formInputs.address1.trim() === '') newErrorsInput.address1 = true;

  if (formInputs.city.trim() === '') newErrorsInput.city = true;

  if (!formInputs.state) newErrorsInput.state = true;

  if (formInputs.zip.length !== 5) newErrorsInput.zip = true;

  if (!formInputs.country) newErrorsInput.country = true

  if (formInputs.nameOnCard.trim() === '') newErrorsInput.nameOnCard = true

  if (formInputs.cardNumber.length !== 19) newErrorsInput.cardNumber = true

  if (formInputs.expDate.length !== 5) newErrorsInput.expDate = true

  if (formInputs.cvv.length !== 3) newErrorsInput.cvv = true

  if (emailPattern.test(formInputs.emailAddress)) {
    const emailAddress = formInputs.emailAddress;

    if (emailAddress.length < 6 || emailAddress.length > 50) {
      newErrorsInput.emailAddress = true;
    } else {
      var symbolPattern = /[!#$%^&*()+\=\[\]{};':"\\|,<>\/?]/;
      if (symbolPattern.test(emailAddress)) {
        newErrorsInput.emailAddress = true;
      }
    }
  } else {
    newErrorsInput.emailAddress = true;
  }

  if (formInputs.password.length < 8) {
    newErrorsInput.password = true;
  }

  if (formInputs.password !== formInputs.confirmedPassword) {
    newErrorsInput.confirmedPassword = true;
  }

  if (Object.keys(newErrorsInput).length > 0) {
    setFormInputs({ ...formInputs, errors: newErrorsInput });
    return false;
  } else {
    setFormInputs({ ...formInputs, errors: {} });
    return true;
  }
}