import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

import editpageBoostTraffic from '../../../images/marketingBoost.png';
import socialMedia from '../../../images/socialMedia.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainWebBuilderHeadingMobile">
      <div className="webBuilderHeadingAlignMobile">
        <h1>
          <span> EditPage Marketing </span>
        </h1>
        <div className="webBuilderSubHeadingMobile">
          EditPage will help you market your website to the world.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Boost Traffic</div>
        <div className="WTBdescriptionMobile">
          We make it easy for people to see who you are on the web. EditPage
          boost traffic feature is simple to use and easy to understand. Grow
          your business fast.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="190"
            src={editpageBoostTraffic}
            alt="Editpage website builder screenshot"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Social Media</div>
        <div className="WTBdescriptionMobile">
          Connecting with the right audience at the right time can really help
          sales grow. Making sure you run the best social media campaigns.
        </div>
        <br />
        <center>
          <img
            width="270"
            height="210"
            src={socialMedia}
            alt="Chef wearing glasses"
          />
        </center>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
