import React from 'react';
import { useMediaQuery } from 'react-responsive';
import SearchIcon from '@material-ui/icons/Search';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="domainRegSectionMobile">
      <div className="domainHeadingMobile">Get the perfect domain name</div>
      <div className="domainInputAreaMobile">
        <div className="input-group mobileDomainInput">
          <input
            type="text"
            className="form-control domainInput"
            placeholder="Search Domains"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary domainButtonMobile"
              type="button"
            >
              <SearchIcon style={{ fontSize: '37px' }} />
            </button>
          </div>
        </div>
      </div>
      <div className="domainAdvertAreaMobile">
        <div className="domainAdvertMobile">
          <div className="domainTypesListMobile">
            <div className="redDotMobile">.</div>com
            <div className="redDotMobile">.</div>co
            <div className="redDotMobile">.</div>org
            <div className="redDotMobile">.</div>online
            <div className="redDotMobile">.</div>biz
          </div>
          <div className="circleSVG">
            <svg
              width="53px"
              height="53px"
              viewBox="0 0 53 53"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs></defs>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Domain-Name-Registration"
                  transform="translate(-685.000000, -590.000000)"
                  fill="#DD5D27"
                >
                  <circle id="Oval" cx="711.5" cy="616.5" r="26.5"></circle>
                </g>
              </g>
            </svg>
          </div>
          <div className="oneWithCircle">1</div>
          <div className="salesSloganFreeDomain">
            Year free domain name with qualified
            <br /> website purchase
          </div>
        </div>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
