import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import authReducer from '../reducers/authReducer';
import domainReducer from '../reducers/domainReducer';
import userReducer from '../reducers/userReducer';
import orderReducer from '../reducers/orderReducer';
import websiteReducer from '../reducers/websiteReducer';
import alertReducer from '../reducers/alertReducer';
import emailReducer from '../reducers/emailReducer';
import cartReducer from "../reducers/cartReducer";

const store = createStore(
  combineReducers({
    auth: authReducer,
    domain: domainReducer,
    user: userReducer,
    order: orderReducer,
    website: websiteReducer,
    alert: alertReducer,
    email: emailReducer,
    cart: cartReducer
  }),
  applyMiddleware(thunk)
);

export default store;
