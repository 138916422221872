import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../NewNavBar';
import { logoutUser } from '../../actions/authActions';

class Authentication extends Component {
  constructor() {
    super();

    this.state = {
      toggleReg: false,
    };
  }

  showLogin() {
    this.setState({
      toggleReg: false,
    });
  }

  showReg() {
    this.setState({
      toggleReg: true,
    });
  }

  logout() {
    this.props.dispatch(logoutUser());
  }

  render() {
    return (
      <div>
        {this.props.loggedIn ? (
          <NavBar
            loggedIn={this.props.loggedIn}
            currentUser={this.props.currentUser}
            logout={this.logout.bind(this)}
          />
        ) : (
          <NavBar />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    currentUser: state.auth.currentUser,
  };
};

export default connect(mapStateToProps)(Authentication);
