import React from 'react';
import './OnlineStore.css';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';
import Vector from '../../../images/marketing/onlineStore/Vector.png';
import HostingEditor from '../../../images/marketing/onlineStore/hosting-editor.jpg';
import Img from '../../../images/marketing/onlineStore/Img.jpg';
import ViewQuilt from '../../../images/marketing/onlineStore/view_quilt.png';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1">
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <img className="circle-1 img-fluid" src={Shape} alt="circle" />
        <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
        <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
        <h1 className="fw-bold section1-head1">EditPage Online Store</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          EditPage offers a simple ecommerce solution to get you into business.
        </p>
        <button
          style={{ borderRadius: '2px' }}
          className="btn-primary demand-btn"
          type="button"
          id="button-addon2"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </button>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6">
          <div className="img-text">
            <img src={Vector} alt='Vector' />
            STORE PAGE
          </div>
          <h1 className="h1">All the tools you need</h1>
          <p>
            Don't miss a sale because of some problem. Our system will help you
            sell more product faster and better. Track your revenue and make
            sure you're making the sales to succeed.
          </p>
          <a href="/onlinestore">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6 img-wrapper">
          <img
            className="img-fluid"
            style={{ width: '85%' }}
            src={HostingEditor}
            alt="Hosting Editor"
          />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="img-wrapper col-md-6 side-image-alignment">
          <img className="img-fluid" src={Img} alt='onlineStore'  />
        </div>
        <div className="cart-text col-md-4 offset-md-2">
          <div className="img-text">
            <img src={ViewQuilt} alt='View Quit' />
            TEMPLATE
          </div>

          <h1 className="h1">eCommerce Templates</h1>
          <p>
            We make it simple to setup your store so you can focus on the
            business instead of the tech. If we don't have a template you need,
            just let us know and we will build one for you.
          </p>
          <a href="/onlinestore">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="blue-section container">
      <div className="blue-section-text col-md-7">
        <h1>Start Crafting your Website</h1>
        <p>
          Get a new experience with the most simple & easy builder on the
          internet right now.
        </p>
      </div>
      <button className="btn btn-dark getStarted-btn" type="submit">
        Start Building
      </button>
    </section>
  </Desktop>
);

export default DesktopResponsive;
