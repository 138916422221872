import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainFeaturesHeadingMobile">
      <div className="featuresHeadingAlignMobile">
        <h1>
          <span> EditPage Press </span>
        </h1>
        <div className="featuresSubHeadingMobile">
          Need more information about EditPage? Here you'll find it.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Press materials</div>
        <div className="WTBdescriptionMobile">
          Easy to find and use documents that explain how EditPage works in more
          detail.
        </div>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Want to talk to us?</div>
        <div className="WTBdescriptionMobile">
          Please <a href="/contact">contact us</a> if you have any questions.
          You can also find us on{' '}
          <a href="http://www.facebook.com/editpagehq">Facebook</a> and{' '}
          <a href="http://www.twitter.com/editpagehq">Twitter</a>.
        </div>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
