import React from 'react';
import { useMediaQuery } from 'react-responsive';
import '../Styles.css';
import './WebBuilder.css';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Template1 from '../../../images/marketing/Template1.png';
import Template2 from '../../../images/marketing/Template2.png';
import Template3 from '../../../images/marketing/Template3.png';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';
import LinkArrow from '../../../images/marketing/north_east.svg';
import Illustration from '../../../images/marketing/webBuilder/Illustration.png';
import Illustration2 from '../../../images/marketing/webBuilder/Illustration2.png';
import Illustration3 from '../../../images/marketing/webBuilder/Illustration3.png';
import OrngArrow from '../../../images/marketing/webBuilder/orngArrow.svg';


const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1" style={{ marginBottom: '50px' }}>
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <img className="circle-1 img-fluid" src={Shape} alt="circle" />
        <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
        <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
        <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
        <h1 className="fw-bold section1-head1">EditPage Website Builder</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          EditPage has an intuitive website builder made for anyone.
        </p>
        <button
          style={{ borderRadius: '2px' }}
          className="btn-primary demand-btn"
          type="button"
          id="button-addon2"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </button>
      </div>
    </section>
    <section className="section">
      <div
        className="
          Intuitive-wrap
          container
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <h1 className="section-head">Intuitive Features</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          Building a website has never been this easy. Our pre-built templates
          are full feature websites ready to go. <br />
          <br />
          Update the text, change the color, add some images then publish.
        </p>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="cart-text col-md-6">
          <h1 className="noDrag">No Drag and Drop</h1>
          <p>
            On Editpage you can directly edit the elements from our stunning
            template. You can choose what category and which template do you
            need. The template will appear on builder page as an editable
            template. Sounds good right ?
          </p>
          <a href="/webbuilder">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src={Illustration} alt='' />
        </div>
      </div>
    </section>
    <section className="container host-section-2">
      <div className="row">
        <div className="col-md-6 side-image-alignment">
          <img className="img-fluid" src={Illustration2} alt='' />
        </div>
        <div className="cart-text col-md-4 offset-md-2">
          <h1 className="click-Edit">Simple Click and Edit</h1>
          <p>
            In our builder, you can simply click and edit the elements whatever
            you want and the tool will be appear on the right side on the
            builder page. So, you will know what tool that available on our
            product.
          </p>
          <a href="/webbuilder">{'Learn More ->'}</a>
        </div>
      </div>
    </section>
    <section className="container host-section-2 border-btm">
      <div className="row">
        <div className="cart-text col-md-6 side-image-reverse-alignment">
          <h1 className="colorSection">Simple Color Selection</h1>
          <p>
            Selection the color will be simply fast and easier as you wish. You
            can change color from the toolbar on top right of feature menu.
            Adding gradient and texture also included on there. Really know’s
            your needed.
          </p>
          <a href="/webbuilder">{'Learn More ->'}</a>
        </div>
        <div className="col-md-6">
          <img className="img-fluid" src={Illustration3} alt='' />
        </div>
      </div>
    </section>
    <section className="section">
      <div
        className="
          Intuitive-wrap
          container
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <h2 className="section-head">
          We have many templates for speed up your workflow
        </h2>

        <div className="mr-t-30 d-flex align-items-center text-center see-link-wrap">
          <div className="d-flex">
            <a className="see-link" style={{ color: '#e87443' }} href="/webbuilder">
              Explore all Templates
            </a>
            <img className="see-arrow" src={OrngArrow} alt="/webbuilder" />
          </div>
          <div className="d-flex">
            <a className="see-link" href="/webbuilder">
              Request Templates{' '}
            </a>
            <img className="see-arrow" src={LinkArrow} alt="" />
          </div>
        </div>
      </div>
    </section>
    <section>
      <div className="imgs-wrap">
        <div className="tamp-image">
          <img className="template-img img-fluid" src={Template1} alt="" />
          <span className="temp-span">Templates 001</span>
        </div>
        <div className="tamp-image">
          <img className="template-img img-fluid" src={Template2} alt="" />
          <span className="temp-span">Templates 002</span>
        </div>

        <div className="tamp-image">
          <img className="template-img img-fluid" src={Template2} alt="" />
          <span className="temp-span">Templates 002</span>
        </div>
        <div className="tamp-image">
          <img className="template-img img-fluid" src={Template3} alt="" />
          <span className="temp-span">Templates 003</span>
        </div>
      </div>
    </section>
    <section className="blue-section container">
      <div className="blue-section-text col-md-7">
        <h1>Start Crafting your Website</h1>
        <p>
          Get a new experience with the most simple & easy builder on the
          internet right now.
        </p>
      </div>
      <button
        className="btn btn-dark getStarted-btn"
        onClick={() => {
          history.push('/signup');
        }}
        type="submit"
      >
        Start Building
      </button>
    </section>
  </Desktop>
);

export default DesktopResponsive;
