import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1" style={{ marginBottom: '50px' }}>
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <h1 className="fw-bold section1-head1">EditPage Press</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          Press materials
        </p>
      </div>
      <img className="circle-1 img-fluid" src={Shape} alt="circle" />
      <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
      <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
      <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
    </section>
    <div className="featuresBody">
      <div className="websiteToolbox">
        <div className="WTBHeading">Press materials</div>
        <div className="WTBdescription">
          EditPage delivers a simple interface that makes building a website
          easy. In just three clicks, you can have a beautiful website thats
          ready to publish.
        </div>
      </div>
      <div className="easyDomainReg">
        <div className="easyDomainRegHeading">Want to talk to us?</div>
        <div className="easyDomainRegDescription">
          Please <a href="/contact">contact us</a> if you have any questions.{' '}
          <br />
          You can also find us on{' '}
          <a href="http://www.facebook.com/editpagehq">Facebook</a> and{' '}
          <a href="http://www.twitter.com/editpagehq">Twitter</a>.
        </div>
      </div>
    </div>
  </Desktop>
);

export default DesktopResponsive;
