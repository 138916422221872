import React from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import DesktopResponsive from './responsive/Support/DesktopResponsive';
import MobileResponsive from './responsive/Support/MobileResponsive';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontFamily: 'Montserrat-Regular',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Support(props) {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EditPage: Support</title>
        <link rel="canonical" href="https://www.editpage.com/support" />
      </Helmet>
      <DesktopResponsive
        history={props.history}
        classes={classes}
        Accordion={Accordion}
        AccordionSummary={AccordionSummary}
        ExpandMoreIcon={ExpandMoreIcon}
        AccordionDetails={AccordionDetails}
        Typography={Typography}
      />
      <MobileResponsive
        history={props.history}
        classes={classes}
        Accordion={Accordion}
        AccordionSummary={AccordionSummary}
        ExpandMoreIcon={ExpandMoreIcon}
        AccordionDetails={AccordionDetails}
        Typography={Typography}
      />
    </div>
  );
}
