import React from 'react';
import { useMediaQuery } from 'react-responsive';
import BizIcon from '../../../images/marketing/biz.png';
import ComIcon from '../../../images/marketing/com.png';
import OrgIcon from '../../../images/marketing/org.png';
import NetIcon from '../../../images/marketing/net.png';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Circle4 from '../../../images/marketing/featureImages/circle-4.svg';
import SearchIcon from '../../../images/marketing/search.svg';

import DomainResults from '../../presentation/DomainResults/DomainResults';
import './DomainRegistration.css';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({
  open,
  domainName,
  error,
  handleKeyDown,
  handleDomainOpen,
  handleOnChange,
}) => (
  <Desktop>
    {open ? (
      <DomainResults domainName={domainName} />
    ) : (
      <div>
        <section className="section-1" id="domain-section-1">
          <div
            className="
            d-flex
            flex-column
            align-items-center
            text-center
            position-relative
          "
          >
            <img
              className="domain-circle-1 img-fluid"
              src={Shape}
              alt="circle"
            />
            <img
              className="domain-circle-2 img-fluid"
              src={Shape2}
              alt="circle"
            />
            <img
              className="domain-circle-3 img-fluid"
              src={Shape3}
              alt="circle"
            />
            <img
              className="domain-circle-4 img-fluid"
              src={Circle4}
              alt="circle"
            />
            <h1 className="fw-bold section1-head1">
              Get the perfect <br /> domain name
            </h1>
            <div className="search d-flex">
              <div className="input-group">
                <input
                  type="text"
                  onKeyDown={handleKeyDown}
                  onChange={handleOnChange}
                  className="form-control mr-r-5 find-input"
                  placeholder="Search your desired domain here..."
                  aria-label="Search Domain"
                  aria-describedby="button-addon2"
                  value={domainName.name}
                />
                <button
                  className="btn-primary demand-btn-search"
                  type="button"
                  onClick={handleDomainOpen}
                  id="button-addon2"
                >
                  <img src={SearchIcon} alt="" />
                </button>
              </div>
            </div>
          </div>
        </section>
        <div></div>
        <section className="container">
          <div className="row">
            <div className="domain-card biz col-md-3">
              <div className="domain-img-wrap">
                {' '}
                <img src={BizIcon} style={{ width: '40%' }} alt="img" />
              </div>
              <div className="domain-card-pera">
                <span className="pera-span">.biz</span>
                <p>Get the best domain for your serious business future.</p>
              </div>
              <div className="years">
                <span className="start-from">Start form</span>
                <h2 className="domain-h2">$25/year</h2>
              </div>
              <button
                className="btn-primary  domain-card-btn"
                type="button"
                id="button-addon2"
              >
                Search Domain
              </button>
            </div>
            <div className="domain-card com col-md-3 col-sm-10">
              <div className="domain-img-wrap">
                <img src={ComIcon} style={{ width: '57%' }} alt="img" />
              </div>
              <div className="domain-card-pera">
                <span className="pera-span">.com</span>
                <p>Get the best domain for your serious business future.</p>
              </div>
              <div className="years">
                <span className="start-from">Start form</span>
                <h2 className="domain-h2">$25/year</h2>
              </div>
              <button
                className="btn-primary  domain-card-btn"
                type="button"
                id="button-addon2"
              >
                Search Domain
              </button>
            </div>
            <div className="domain-card org col-md-3">
              <div className="domain-img-wrap">
                <img src={OrgIcon} style={{ width: '25%' }} alt="img" />
              </div>
              <div className="domain-card-pera">
                <span className="pera-span">.org</span>
                <p>Get the best domain for your serious business future.</p>
              </div>
              <div className="years">
                <span className="start-from">Start form</span>
                <h2 className="domain-h2">$25/year</h2>
              </div>
              <button
                className="btn-primary  domain-card-btn"
                type="button"
                id="button-addon2"
              >
                Search Domain
              </button>
            </div>
            <div className="domain-card net col-md-3">
              <div className="domain-img-wrap">
                <img src={NetIcon} style={{ width: '40%' }} alt="img" />
              </div>
              <div className="domain-card-pera">
                <span className="pera-span">.net</span>
                <p>Get the best domain for your serious business future.</p>
              </div>
              <div className="years">
                <span className="start-from">Start form</span>
                <h2 className="domain-h2">$25/year</h2>
              </div>
              <button
                className="btn-primary  domain-card-btn"
                type="button"
                id="button-addon2"
              >
                Search Domain
              </button>
            </div>
          </div>
        </section>
      </div>
    )}
  </Desktop>
);

export default DesktopResponsive;
