import React, { Component } from 'react';

import DesktopResponsive from './responsive/OnlineStore/DesktopResponsive';
import MobileResponsive from './responsive/OnlineStore/MobileResponsive';

class OnlineStore extends Component {
  render() {
    return (
      <div>
        <DesktopResponsive history={this.props.history} />
        <MobileResponsive history={this.props.history} />
      </div>
    );
  }
}

export default OnlineStore;
