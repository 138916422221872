import React from 'react';
import { Nav } from 'react-bootstrap';
import { history } from '../helpers/history';
import logoWhite from '../images/logo-white.png';
import { getCurrentUser } from '../actions/authActions';
import { logoutUser } from '../actions/authActions';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as DashboardIcon } from '../images/icons/dashboard.svg';
import { ReactComponent as WebsiteIcon } from '../images/icons/web.svg';
import { ReactComponent as AnalyticsIcon } from '../images/icons/analyticsIcon.svg';
import { ReactComponent as DomainIcon } from '../images/icons/domainIcon.svg';
import { ReactComponent as StorageIcon } from '../images/icons/storage.svg';
import { ReactComponent as TrafficIcon } from '../images/icons/traffic.svg';
import { ReactComponent as EmailIcon } from '../images/icons/email.svg';
import { ReactComponent as CartIcon } from '../images/icons/cart.svg';
import { ReactComponent as AccountIcon } from '../images/icons/accountIcon.svg';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const LogoutIcon = (props) =>(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#fc5a5a" xmlns="http://www.w3.org/2000/svg">
  <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="#fc5a5a"/>
  </svg>
)

function SideBar(props) {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutUser())
    history.push('/');
  };

  React.useEffect(() => {
    dispatch(getCurrentUser({ tokenID: localStorage.getItem('token') }));
  }, [dispatch]);

  return (
    <>
      <div className="sidebar">
        <div className="sidebarBody">
        <div className="sidebarContainer">
          <Nav.Link onClick={() => history.push('/dashboard')}>
            <img
              className="newLogo"
              src={logoWhite}
              alt=""
            />
          </Nav.Link>
          <div
            className="sidebarItemContainer"
            onClick={() => history.push('/dashboard')}
          >
            <div className={window.location.href.split('/')[3] === 'dashboard' ? 'selector' : 'emptySelector'}></div>
            <div className={window.location.href.split('/')[3] === 'dashboard' ? 'activeItem' : 'sidebarItem'}>
              <DashboardIcon />
              <div className={window.location.href.split('/')[3] === 'dashboard' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Dashboard</div>
            </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/editor')}>
          <div className={window.location.href.split('/')[3] === 'editor' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'editor' ? 'activeItem' : 'sidebarItem'}>
            <WebsiteIcon />
            <div className={window.location.href.split('/')[3] === 'editor' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Website</div>
          </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/analytics')}>
          <div className={window.location.href.split('/')[3] === 'analytics' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'analytics' ? 'activeItem' : 'sidebarItem'}>
            <AnalyticsIcon />
            <div className={window.location.href.split('/')[3] === 'analytics' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Analytics</div>
          </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/register')}>
          <div className={window.location.href.split('/')[3] === 'register' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'register' ? 'activeItem' : 'sidebarItem'}>
            <DomainIcon />
            <div className={window.location.href.split('/')[3] === 'register' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Register Domain</div>
          </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/storage')}>
          <div className={window.location.href.split('/')[3] === 'storage' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'storage' ? 'activeItem' : 'sidebarItem'}>
            <StorageIcon />
            <div className={window.location.href.split('/')[3] === 'storage' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Storage</div>
          </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/traffic')}>
          <div className={window.location.href.split('/')[3] === 'traffic' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'traffic' ? 'activeItem' : 'sidebarItem'}>
            <TrafficIcon />
            <div className={window.location.href.split('/')[3] === 'traffic' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Boost Traffic</div>
          </div>
          </div>
          <div
            className="sidebarItemContainer"
            onClick={() => history.push('/email')}
          >
          <div className={window.location.href.split('/')[3] === 'email' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'email' ? 'activeItem' : 'sidebarItem'}>
            <EmailIcon />
            <div className={window.location.href.split('/')[3] === 'email' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Email</div>
          </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/store')}>
          <div className={window.location.href.split('/')[3] === 'store' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'store' ? 'activeItem' : 'sidebarItem'}>
            <CartIcon />
            <div className={window.location.href.split('/')[3] === 'store' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Store</div>
          </div>
          </div>
          <div className="sidebarItemContainer" onClick={() => history.push('/account')}>
          <div className={window.location.href.split('/')[3] === 'account' ? 'selector' : 'emptySelector'}></div>
          <div className={window.location.href.split('/')[3] === 'account' ? 'activeItem' : 'sidebarItem'}>
            <AccountIcon />
            <div className={window.location.href.split('/')[3] === 'account' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Account</div>
          </div>
          </div>
          {currentUser.role === 'admin' ?
            <div className="sidebarItemContainer" onClick={() => history.push('/admin/dashboard')}>
            <div className={window.location.href.split('/')[3] === 'admin' ? 'selector' : 'emptySelector'}></div>
            <div className={window.location.href.split('/')[3] === 'admin' ? 'activeItem' : 'sidebarItem'}>
              <AdminPanelSettingsIcon />
              <div className={window.location.href.split('/')[3] === 'admin' ? 'menu-active' : 'manrope-medium-mirage-14px'}>Admin</div>
            </div>
            </div>
          : ''}
          <div className="sidebarItemContainer" onClick={logout}>
          <div className="emptySelector"></div>
          <div className={window.location.href.split('/')[3] === 'logout' ? 'activeItem' : 'sidebarItem'}>
            <LogoutIcon />
            <div className="manrope-medium-mirage-14px sidebarLogout">Logout</div>
          </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
