import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import logo from '../../images/logo.png';

const Success = () => {
  return (
    <div>
      <br />
      <center>
        <h5 className="forgotPass">
          Success! Now you can login
          <br />
          <br />
          <br />
          <a href="/login">Login</a>
        </h5>
      </center>
      <br />
    </div>
  );
};

const LoadForm = ({ updatePassword }) => {
  return (
    <div>
      <center>
        <h5 className="forgotPass">Update Password</h5>
      </center>
      <br />
      <Form onSubmit={updatePassword}>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            id="password"
            type="password"
            placeholder="Enter password"
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            id="passwordConfirmation"
            type="password"
            placeholder="Enter password confirmation"
            autoComplete="off"
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Reset
        </Button>
      </Form>
    </div>
  );
};

class UpdatePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
    this.updatePassword = this.updatePassword.bind(this);
  }

  updatePassword(event) {
    event.preventDefault();
    let password = event.currentTarget[0].value;
    let passwordConfirmation = event.currentTarget[1].value;
    let urlCode = window.location.href.split('/updatepassword/')[1];
    if (password === passwordConfirmation) {
      fetch(process.env.REACT_APP_BACKEND_URL + `/user/reset/${urlCode}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: password }),
        mode: 'cors',
      }).then((response) => {
        if (!response.ok) {
          console.log('We got an error');
        }
        return response.json();
      });
      this.setState({ submitted: true });
      //history.push('/login');
    }
  }

  render() {
    return (
      <div>
        <div className="logoWiz spacerFromLogo">
          <img width="196" height="74" src={logo} alt="" />
        </div>
        <div className="loginForm">
          {this.state.submitted ? (
            <Success />
          ) : (
            <LoadForm updatePassword={this.updatePassword} />
          )}
        </div>
      </div>
    );
  }
}

export default UpdatePassword;
