import React, { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import styles from './CheckoutDomain.module.css';

import Select from 'react-select';

import Visa from '../../../images/cards/visa.png';
import Mastercard from '../../../images/cards/mastercard.png';
import AmericanExpress from '../../../images/cards/american-express.png';
import Discover from '../../../images/cards/discover.png';
import Bitcoin from '../../../images/cards/bitcoin.png';
import LockIcon from '../../../images/icons/lock-closed.svg';
import EyeIcon from "../../../images/icons/eye.svg"

import { validateInputs } from './validation';
import { stateOptions, countryOptions } from './selectValues';
import { submitRegisterDomainPayment } from '../../../actions/authActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

function CheckoutDomain(props) {
  const { cart, removeFromCart, setCart } = props;
  const [processingPayment, setProcessingPayment] = React.useState(false);

  const [formInputs, setFormInputs] = useState({
    cardNumber: '',
    expDate: '',
    cvv: '',
    firstName: '',
    lastName: '',
    nameOnCard: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    emailAddress: '',
    password: '',
    confirmedPassword: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const loadStripe = () => {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement('script');
      s.id = 'stripe-script';
      s.type = 'text/javascript';
      s.src = 'https://js.stripe.com/v1/';
      s.onload = () => {
        window['Stripe'].setPublishableKey(process.env.REACT_APP_STRIPE_API);
      };
      window.document.body.appendChild(s);
    }
  };

  const createCharge = React.useCallback(() => {
    if (formInputs.cardNumber) {
      window.Stripe.card.createToken(
        {
          number: formInputs.cardNumber,
          exp_month: formInputs.expDate.substring(0, 2),
          exp_year: formInputs.expDate.substring(3, 5),
          cvc: formInputs.cvv,
        },
        (status, response) => {
          if (status === 200) {
            if (props.currentUser._id) {
              let req = null;
              req = req + 1;
              const cardData = {
                token: response.id,
                userId: props.currentUser._id,
                chargeAmount: props.cartTotal.toFixed(2).toString(),
                purchasedDomains: props.cart,
                rawData: response,
              };
              if (req === 1) {
                fetch(
                  process.env.REACT_APP_BACKEND_URL + '/order/create/domain',
                  {
                    method: 'POST',
                    headers: {
                      'x-access-token': props.currentUser.accessToken,
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(cardData),
                    mode: 'cors',
                  }
                ).then((response) => {
                  if (!response.ok) {
                    console.log('Heres our error', response.statusText);
                  } else if (response.ok) {
                    setProcessingPayment(false);
                    // Show header & footer
                    let header = document.getElementsByClassName('navbar');
                    let footer = document.getElementsByClassName('page-footer');
                    header[0].style.visibility = 'visible';
                    footer[0].style.visibility = 'visible';
                    header[0].style.height = '';
                    // Now lets log user in.
                    history.push('/dashboard');
                  }
                });
              }
            }
          } else {
            setProcessingPayment(false);
            if (response.error.code === 'incorrect_number') {
              console.log('Form inputs errors 1: ', formInputs.errors)
              setFormInputs(formInputs => ({ ...formInputs, errors: { ...formInputs.errors, cardNumber: true } }));
            } else if (response.error.code === 'invalid_expiry_year') {
              console.log('Form inputs errors 2: ', formInputs.errors)
              setFormInputs(formInputs => ({ ...formInputs, errors: { ...formInputs.errors, expDate: true } }));
            } else {
              setFormInputs(formInputs => ({ ...formInputs, errors: { ...formInputs.errors, expDate: false, cardNumber: false } }));
            }
          }
        }
      );
    }
  }, [formInputs.cardNumber, formInputs.expDate, formInputs.cvv, props.cartTotal, props.cart, props.currentUser.accessToken, props.currentUser._id, setProcessingPayment]);

  const getColorStyles = (isError) => {
    return {
      control: (styles, { isFocused }) => ({
        ...styles,
        minHeight: "47px",
        border: `1px solid ${isError ? '#dc143c !important' : isFocused ? '#2e2c34 !important' : '#ebeaed'}`,
        boxShadow: `1px solid ${isError ? '#dc143c !important' : isFocused ? '#2e2c34 !important' : ''}`,
        borderRadius: "4px",
        fontSize: "14px",
      }),
      option: (styles) => ({
        ...styles,
        fontSize: "14px",
      }),
    };
  };

  const handleInputChange = (inputName) => (e) => {
    let formattedInput = e.target.value;

    switch (inputName) {
      case 'cardNumber':
        formattedInput = formatCardNumber(formattedInput);
        break;
      case 'expDate':
        formattedInput = formatExpiryDate(formattedInput);
        break;
      case 'zip':
        formattedInput = formatZipCode(formattedInput);
        break;
      default:
        break;
    }

    setFormInputs({ ...formInputs, [inputName]: formattedInput });
  };

  const formatCardNumber = (input) => {
    return input.replace(/\D/g, '').replace(/(\d{4})/g, '$1 ').trim();
  };

  const formatZipCode = (input) => {
    return input.replace(/\D/g, '').replace(/^(.{5})(.*)$/, '$1');
  }

  const formatExpiryDate = (input) => {
    const cleanedInput = input.replace(/[^\d/]/g, '');
    const match = /^(\d{0,2})\/?(\d{0,2})$/.exec(cleanedInput);

    if (match) {
      const formattedDate = match.slice(1, 3).filter(Boolean).join('/');
      return formattedDate.length <= 5 ? formattedDate : formattedDate.slice(0, 5);
    }

    return '';
  };

  const updateDomainYears = (domain, increment) => {
    const currentCartCopy = [...cart];
    const domainToUpdate = currentCartCopy.find(item => item.domainName === domain.domainName);

    if (domainToUpdate) {
      domainToUpdate.years += increment;

      if (domainToUpdate.years === 0) {
        removeFromCart(domain);
        return;
      }

      setCart(currentCartCopy);
    }
  }

  const addYearToDomain = (el) => {
    updateDomainYears(el, 1);
  }

  const removeYearToDomain = (el) => {
    updateDomainYears(el, -1);
  }

  const showPayment = () => {
    const inValid = validateInputs(formInputs, setFormInputs);
    inValid && onSubmit(formInputs);
  };

  function transformString(str) {
    let transformedString = str.replace(/([a-z])([A-Z])/g, '$1 $2');
    return transformedString.charAt(0).toUpperCase() + transformedString.slice(1).toLowerCase();
  }

  const onSubmit = () => {
    // START LOADER!
    setProcessingPayment(true);

    // Deleting cc info for safety.
    delete formInputs.cardNumber;
    delete formInputs.expDate;
    delete formInputs.cvv;
    delete formInputs.confirmedPassword;

    // This method creates a new user and updates its attributes then returns the user while logging them in.
    // If everything passes validation and the charge is cleared. Then login user to the dashboard
    props.submitRegister(formInputs);
  };

  React.useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    loadStripe();
  }, []);

  React.useEffect(() => {
    createCharge();
  }, [props.currentUser._id, createCharge, formInputs.emailAddress]);

  const cartItems = props.cart.map((el) => (
    <tr key={el.domainName}>
      <td>{el.domainName}</td>
      <td className={styles.checkoutOrderPrice}>
        <RemoveIcon onClick={() => removeYearToDomain(el)} style={{ cursor: "pointer" }} />
        <span>{el.years}</span>
        <AddIcon onClick={() => addYearToDomain(el)} style={{ cursor: "pointer" }} />
      </td>
      <td style={{ textAlign: "end" }}>
        <div style={{ position: "relative" }}>
          <b>
            {el.premiumName === 'true'
              ? ` $` +
              Number(el.premiumPrice)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : ` $` + el.regularPrice}
          </b>
        </div>
      </td>
    </tr>
  ));

  return (
    <div className={styles.checkoutWrapper}>
      {processingPayment ? (
        <div className={styles.processingPaymentContent}>
          <div className={styles.processingPaymentCircle}>
            Processing...
            <br />
            <br />
            <CircularProgress color="primary" />
          </div>
        </div>
      ) : (<>
        <div className={styles.checkoutLeftContent}>
          <h1 className={styles.checkoutTitle}>Checkout</h1>
          {formInputs?.errors && (
            <div className={styles.errorTextTopBg}>
              <p className={styles.errorTextTop}>
                Please complete the following fields:{" "}
                {Object.keys(formInputs.errors)
                  .map((key, index) => (
                    formInputs.errors[key] !== undefined && (
                      <React.Fragment key={key}>
                        {transformString(key)}{index < Object.keys(formInputs.errors).length - 1 ? ',' : '.'}{" "}
                      </React.Fragment>
                    )
                  ))
                }
              </p>
            </div>
          )}
          <form className={styles.checkoutForm}>
            <h3 className={styles.checkoutSubtitle}>Billing address</h3>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} htmlFor="firstName">First name:</label>
                <input className={`${styles.formInput} ${formInputs.errors?.firstName && styles.formInputError}`} placeholder="Your first name" onChange={handleInputChange("firstName")} name="firstName" type="text" />
              </div>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="lastName">Last name:</label>
                <input className={`${styles.formInput} ${formInputs.errors?.lastName && styles.formInputError}`} placeholder="Your last name" onChange={handleInputChange("lastName")} name="lastName" type="text" />
              </div>
            </div>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="address1">Address line 1</label>
                <input className={`${styles.formInput} ${formInputs.errors?.address1 && styles.formInputError}`} placeholder="Your address" onChange={handleInputChange("address1")} name="address1" type="text" />
              </div>
            </div>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="address2">Address line 2</label>
                <input className={styles.formInput} placeholder="Your address" onChange={handleInputChange("address2")} name="address2" type="text" />
              </div>
            </div>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="city">City</label>
                <input className={`${styles.formInput} ${formInputs.errors?.city && styles.formInputError}`} placeholder="Your city" onChange={handleInputChange("city")} name="city" type="text" />
              </div>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="state">State</label>
                <Select
                  name="state"
                  placeholder="Your state"
                  defaultValue={formInputs.state}
                  options={stateOptions}
                  onChange={(e) => setFormInputs({ ...formInputs, state: e.value })}
                  styles={getColorStyles(formInputs.errors?.state ? true : false)}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
            </div>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="zip">ZIP code</label>
                <input className={`${styles.formInput} ${formInputs.errors?.zip && styles.formInputError}`} placeholder="ZIP code" value={formInputs.zip} onChange={handleInputChange("zip")} name="zip" type="text" />
              </div>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="state">Country</label>
                <Select
                  name="country"
                  placeholder="Your Country"
                  defaultValue={formInputs.country}
                  options={countryOptions}
                  onChange={(e) => setFormInputs({ ...formInputs, country: e.value })}
                  styles={getColorStyles(formInputs.errors?.country ? true : false)}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                />
              </div>
            </div>

            <h3 className={styles.checkoutSubtitle}>Credit Card</h3>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="nameOnCard">Name on card:</label>
                <input className={`${styles.formInput} ${formInputs.errors?.nameOnCard && styles.formInputError}`} placeholder="Enter card holder’s Name" onChange={handleInputChange("nameOnCard")} name="nameOnCard" type="text" />
              </div>
            </div>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="cardNumber">Card number:</label>
                <input
                  className={`${styles.formInput} ${formInputs.errors?.cardNumber && styles.formInputError}`}
                  type="text"
                  id="creditCard"
                  placeholder="XXXX XXXX XXXX XXXX"
                  maxLength="19"
                  value={formInputs.cardNumber}
                  onChange={handleInputChange("cardNumber")}
                  required
                />
              </div>
              <div style={{ display: "flex", gap: "24px" }}>
                <div className={styles.formWrapper}>
                  <label className={styles.formLabel} for="expDate">Expiry date:</label>
                  <input
                    className={`${styles.formInput} ${formInputs.errors?.expDate && styles.formInputError}`}
                    type="text"
                    id="expDate"
                    placeholder="MM/YY"
                    maxLength="5"
                    value={formInputs.expDate}
                    onChange={handleInputChange("expDate")}
                    required
                  />
                </div>
                <div className={styles.formWrapper}>
                  <label className={styles.formLabel} for="cvv">CVV:</label>
                  <input
                    className={`${styles.formInput} ${formInputs.errors?.cvv && styles.formInputError}`}
                    type="password"
                    id="cvv"
                    placeholder="XXX"
                    maxLength="3"
                    value={formInputs.cvv}
                    onChange={handleInputChange("cvv")}
                    required
                  />
                  <span className={styles.cvvText}>
                    Last three digits on signature strip
                  </span>
                </div>
              </div>
            </div>

            <h3 className={styles.checkoutSubtitle}>Account</h3>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="email">Email:</label>
                <input
                  className={`${styles.formInput} ${formInputs.errors?.emailAddress && styles.formInputError}`}
                  onChange={handleInputChange("emailAddress")}
                  value={formInputs.emailAddress}
                  type="email"
                  id="email"
                  placeholder="yourmail@gmail.com"
                  required
                />
              </div>
            </div>
            <div className={styles.formBlock}>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="password">Password:</label>
                <div className={styles.passwordWrapper}>
                  <input
                    style={{ width: "100%" }}
                    className={`${styles.formInput} ${formInputs.errors?.password && styles.formInputError}`}
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="Your password"
                    value={formInputs.password}
                    onChange={handleInputChange("password")}
                    required
                  />
                  <img onClick={() => setShowPassword(!showPassword)} className={styles.iconImg} src={EyeIcon} alt="" />
                </div>
              </div>
              <div className={styles.formWrapper}>
                <label className={styles.formLabel} for="password">Confirm password:</label>
                <input
                  className={`${styles.formInput} ${formInputs.errors?.confirmedPassword && styles.formInputError}`}
                  type={'password'}
                  id="confirm-password"
                  placeholder="Your password"
                  value={formInputs.confirmedPassword}
                  onChange={handleInputChange("confirmedPassword")}
                  required
                />
              </div>
            </div>
          </form>
        </div>
        <div className={styles.checkoutRightContent}>
          <div className={styles.checkoutOrder}>
            <h3 className={styles.checkoutOrderTitle}>Order</h3>
            <div className={styles.checkoutOrderTableScroll}>
              <table class={styles.checkoutOrderTable}>
                <thead className={styles.checkoutOrderTableHead}>
                  <tr>
                    <th>Product</th>
                    <th style={{ paddingLeft: "35px" }}>Years</th>
                    <th style={{ textAlign: "end" }}>Total price</th>
                  </tr>
                </thead>
                <tbody className={styles.checkoutOrderTableContent}>
                  {cartItems}
                </tbody>
              </table>
            </div>

            <div className={styles.checkoutOrderPriceTotal}>
              Total <span>${props.cartTotal.toFixed(2)}</span>
            </div>
            <a className={styles.checkoutAddDiscount} href="#">Add Discount</a>
            <button
              className={styles.checkoutBtn}
              onClick={showPayment}
              disabled="disabled"
            >
              Pay & Sign up
            </button>
            <div className={styles.checkoutCardBlock}>
              <span className={styles.checkoutCardText}>We Accept Following Cards</span>
              <div className={styles.checkoutCardWrapper}>
                <img src={Visa} alt="" />
                <img src={Mastercard} alt="" />
                <img src={AmericanExpress} alt="" />
                <img src={Discover} alt="" />
                <img src={Bitcoin} alt="" />
              </div>
            </div>
            <div className={styles.checkoutInformationText}><img src={LockIcon} alt="" /> Your information will be secured</div>
          </div>
        </div>
      </>)}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    email: state.auth.email,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitRegister: (data) => {
      dispatch(submitRegisterDomainPayment(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CheckoutDomain));
