import constants from '../constants/actionTypes';

var initialState = {
  isCartOpen: false,
  isCartFilled: false,
  totalAmount: 0,
};

export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.TOGGLE_CART:
      return {
        ...state,
        isCartOpen: !state.isCartOpen,
      };
    case constants.TOGGLE_CART_FILLED:
      return {
        ...state,
        isCartFilled: !state.isCartFilled,
      };
    case constants.TOTAL_AMOUNT:
      updated['totalAmount'] = action.results;
      return updated;
    default:
      return state;
  }
};
