import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import officeLayout from '../../../images/officeLayout.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="featuresBody">
      <div className="websiteToolboxMobile">
        <Container>
          <Row>
            <Col>
              <div className="aboutDesc">
                EditPage has developed easy to use tools to allow you to create
                your Website without having to learn code or wasting time
                figuring out complex web builders.
                <br />
                <br />
                At EditPage our mission is to provide the easiest way to build
                and deploy a website on the web.
                <br />
                <br />
              </div>
            </Col>
            <Col>
              <center>
                <img width="329" height="205" src={officeLayout} alt="" />
              </center>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <br />
              <div className="aboutDesc">
                EditPage has focused on building intuitive features that help
                you focus on your message with the intention for everyone being
                able to build their website easily.
                <br />
                <br />
                Our development team at EditPage wanted to build a website
                builder that any person at any skill level could establish their
                web presence.
                <br />
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
