export default {
  USER_REGISTERED: 'USER_REGISTERED',
  USER_LOGGEDIN: 'USER_LOGGEDIN',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_AUTH_ERROR: 'USER_AUTH_ERROR',
  WORD_SUGGESTIONS: 'WORD_SUGGESTIONS',
  GET_USERS: 'GET_USERS',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  UPDATE_USER: 'UPDATE_USER',
  GET_ORDERS: 'GET_ORDERS',
  GET_WEBSITES: 'GET_WEBSITES',
  CREATE_WEBSITE: 'CREATE_WEBSITE',
  SAVE_WEBSITE: 'SAVE_WEBSITE',
  DELETE_WEBSITE: 'DELETE_WEBSITE',
  GET_USER_WEBSITES: 'GET_USER_WEBSITES',
  GET_WEBSITE: 'GET_WEBSITE',
  GET_PUBLIC_WEBSITE: 'GET_PUBLIC_WEBSITE',
  ALERT_SIGNAL: 'ALERT_SIGNAL',
  SEND_EMAIL: 'SEND_EMAIL',
  GET_EMAIL_ACCOUNT: 'GET_EMAIL_ACCOUNT',
  MARK_EMAIL_READ: 'MARK_EMAIL_READ',
  MARK_EMAIL_UNREAD: 'MARK_EMAIL_UNREAD',
  DELETE_EMAIL: 'DELETE_EMAIL',
  TOGGLE_STARRED_EMAIL: "TOGGLE_STARRED_EMAIL",
  DOMAIN_NAME_CHECK: 'DOMAIN_NAME_CHECK',
  USER_REGISTERED_AND_LOGGED_IN: 'USER_REGISTERED_AND_LOGGED_IN',
  GET_EMAIL_BODY: 'GET_EMAIL_BODY',
  TOGGLE_DOMAIN_RESULTS: "TOGGLE_DOMAIN_RESULTS",
  TOGGLE_CART: 'TOGGLE_CART',
  TOGGLE_CART_FILLED: "TOGGLE_CART_FILLED",
  TOTAL_AMOUNT: "TOTAL_AMOUNT"
};
