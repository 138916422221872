import React from 'react';
import { Helmet } from 'react-helmet';
import DesktopResponsive from './responsive/Home/DesktopResponsive';
import MobileResponsive from './responsive/Home/MobileResponsive';
import { toggleDomainResults } from "../actions/domainActions";
import { connect } from 'react-redux';

function Home(props) {
  const [domainName, setDomainName] = React.useState({ name: '' });
  const [error, setError] = React.useState({ type: '', message: '' });
  const { loggedIn, history, isDomainResultsOpen, toggleDomainResults } = props;

  const detectCurrentDomainName = React.useCallback(() => {
    let currentDomain = window.location.hostname;
    console.log('This is the domain: ', window.location.href);
    var referrer = window.location.href;
    console.log('referrer url', referrer);
    if (currentDomain.includes('blockpire')) {
      props.history.push('/gemstatehearing');
      console.log('Current domain: ', currentDomain);
    }
  }, [props.history]);


  React.useEffect(
    () => {
      detectCurrentDomainName();
      if (loggedIn) {
        history.push('/dashboard');
      }
    },
    [loggedIn, detectCurrentDomainName, history]
  );

  const handleDomainClose = () => {
    toggleDomainResults();
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleDomainOpen();
    }
  };

  // const checkIfValidDomain = domain => {
  // Check to see if it has a .something
  //   if (
  //     domain.includes('.com') ||
  //     domain.includes('.net') ||
  //     domain.includes('.co') ||
  //     domain.includes('.xyz') ||
  //     domain.includes('.me') ||
  //     domain.includes('.io')
  //   ) {
  //     return true;
  //   }
  // };

  const handleDomainOpen = event => {
    if (domainName.name && domainName.name.length < 64) {
      toggleDomainResults();
    } else {
      setError({
        type: 'valid-domain',
        message: 'Please enter a valid domain name',
      });
    }
  };

  const handleOnChange = evt => {
    setDomainName({ name: evt.target.value });
  };



  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>EditPage: Simple, easy and affordable website builder</title>
        <meta
          content="EditPage is a simple, easy and affordable website builder. We make creating your website attainable and convenient."
          name="description"
        />
        <meta content="EditPage" name="generator" />
      </Helmet>
      <DesktopResponsive
        open={isDomainResultsOpen}
        domainName={domainName}
        error={error}
        handleKeyDown={handleKeyDown}
        handleDomainClose={handleDomainClose}
        handleDomainOpen={handleDomainOpen}
        handleOnChange={handleOnChange}
      />
      <MobileResponsive
        open={isDomainResultsOpen}
        domainName={domainName}
        error={error}
        handleKeyDown={handleKeyDown}
        handleDomainClose={handleDomainClose}
        handleDomainOpen={handleDomainOpen}
        handleOnChange={handleOnChange}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isDomainResultsOpen: state.domain.isDomainResultsOpen
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDomainResults: () => {
      dispatch(toggleDomainResults())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);