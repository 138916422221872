import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

import domainReg from '../../../images/editpage-domain.jpg';
import editpageEditor from '../../../images/editpage-editor.jpg';
import editpageAnalytics from '../../../images/editpage-analytics.jpg';
import editpageStorage from '../../../images/editpage-storage.jpg';
import editpageEmail from '../../../images/editpage-email.jpg';
import editpageBoostTraffic from '../../../images/editpage-boost-traffic.jpg';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainFeaturesHeadingMobile">
      <div className="featuresHeadingAlignMobile">
        <h1>
          <span> EditPage Features </span>
        </h1>
        <div className="featuresSubHeadingMobile">
          Explore what EditPage offers to setup and publish a beautiful website
          in minutes.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Intuitive Website Editor</div>
        <div className="WTBdescriptionMobile">
          EditPage is your gateway to designing your brand on the internet. In
          just three clicks, you can have a beautiful website that's ready to
          publish.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="95"
            src={editpageEditor}
            alt="Screenshot of EditPage editor"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Website Analytics</div>
        <div className="WTBdescriptionMobile">
          EditPage offers the best tools to identify and grow your audience.
          Straightforward insights that require no guess work.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="95"
            src={editpageAnalytics}
            alt="Screenshot of EditPage analytics"
          />
        </center>
      </div>
      <div className="easyDomainRegMobile">
        <div className="easyDomainRegHeadingMobile">
          Easy Domain Registration
        </div>
        <div className="easyDomainRegDescription">
          Need a domain? We've got you covered. Try EditPage's easy domain
          registration to see if your new website name is available.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="95"
            src={domainReg}
            alt="Screenshot of EditPage domain registration page"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Website Storage</div>
        <div className="WTBdescriptionMobile">
          EditPage gives you all the storage you need with an affordable way to
          upgrade. We know your data is important and we make it easy to manage.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="95"
            src={editpageStorage}
            alt="Screenshot of EditPage storage solution"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Email Hosting</div>
        <div className="WTBdescriptionMobile">
          EditPage knows you want to communicate. Safe, secure and a simple
          setup. Signup and try it out.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="95"
            src={editpageEmail}
            alt="Screenshot of EditPage email client"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Boost Traffic</div>
        <div className="WTBdescriptionMobile">
          EditPage can drive the right kind of traffic to your website. Quick
          and easy to use marketing materials that get results.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="95"
            src={editpageBoostTraffic}
            alt="Screenshot of EditPage boost traffic page"
          />
        </center>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
