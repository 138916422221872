import constants from '../constants/actionTypes';

var initialState = {
  orders: [],
};

export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.GET_ORDERS:
      updated['orders'] = action.results;
      return updated;

    default:
      return state;
  }
};
