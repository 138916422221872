import React from 'react';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import EditPageLogo from '../../images/logo.png';
import TextField from '@material-ui/core/TextField';
import CreditCards from '../../images/creditcards.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import useForm from 'react-hook-form';
import { history } from '../../helpers/history';
import { submitRegisterDomainPayment } from '../../actions/authActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

function PaymentDomain(props) {
  const { register, handleSubmit, errors } = useForm();
  const [userCreated, setUserCreated] = React.useState(false);
  // const [creditCardCharged, setCreditCardCharged] = React.useState(false);
  const [formInfo, setFormInfo] = React.useState({
    cardNumber: '',
    expDate: '',
    cvv: '',
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    emailAddress: '',
    password: '',
  });
  const [processingPayment, setProcessingPayment] = React.useState(false);

  React.useEffect(() => {
    // let shoppingCart = document.getElementsByClassName('editPageShoppingCart');
    // shoppingCart[0].remove();
    loadStripe();
  }, []);



  const handleOnChangeForInput = (e) => {
    // Grab value from input and put into state
    let { name, value } = e.target;

    // Email address validations
    let emailInput = document.getElementById('emailAddress');
    if (name === 'emailAddress' && validateEmail(value)) {
      emailInput.style.borderBottom = '';
    } else if (name === 'emailAddress') {
      emailInput.style.borderBottom = '5px solid red';
    }

    // Credit Card Validations
    let expDateInput = document.getElementById('expDate');
    if (
      name === 'expDate' &&
      value.length === 4 &&
      Number(value.slice(-2)) > 20
    ) {
      expDateInput.style.borderBottom = '';
      // Special way to set state
      setFormInfo((prevInputState) => ({
        ...prevInputState,
        expDate: value,
      }));
    } else if (name === 'expDate') {
      expDateInput.style.borderBottom = '5px solid red';
    }

    if (name === 'cardNumber') {
      // Special way to set state when you have complex forms or
      // when you need to update state with better data after submitting a form
      setFormInfo((prevInputState) => ({
        ...prevInputState,
        cardNumber: value,
      }));
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const loadStripe = () => {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement('script');
      s.id = 'stripe-script';
      s.type = 'text/javascript';
      s.src = 'https://js.stripe.com/v1/';
      s.onload = () => {
        window['Stripe'].setPublishableKey(process.env.REACT_APP_STRIPE_API);
      };
      window.document.body.appendChild(s);
    }
  };

  const createCharge = React.useCallback(() => {
    if (formInfo.cardNumber) {
      window.Stripe.card.createToken(
        {
          number: formInfo.cardNumber,
          exp_month: formInfo.expDate.substring(0, 2),
          exp_year: formInfo.expDate.substring(2, 4),
          cvc: formInfo.cvv,
        },
        (status, response) => {
          if (status === 200) {
            if (props.currentUser._id) {
              let req = null;
              req = req + 1;
              const cardData = {
                token: response.id,
                userId: props.currentUser._id,
                chargeAmount: props.cartTotal.toFixed(2).toString(),
                purchasedDomains: props.cart,
                rawData: response,
              };
              if (req === 1) {
                fetch(
                  process.env.REACT_APP_BACKEND_URL + '/order/create/domain',
                  {
                    method: 'POST',
                    headers: {
                      'x-access-token': props.currentUser.accessToken,
                      Accept: 'application/json',
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(cardData),
                    mode: 'cors',
                  }
                ).then((response) => {
                  if (!response.ok) {
                    console.log('Heres our error', response.statusText);
                  } else if (response.ok) {
                    setProcessingPayment(false);
                    // Show header & footer
                    let header = document.getElementsByClassName('navbar');
                    let footer = document.getElementsByClassName('page-footer');
                    header[0].style.visibility = 'visible';
                    footer[0].style.visibility = 'visible';
                    header[0].style.height = '';
                    // Now lets log user in.
                    history.push('/dashboard');
                  }
                });
              }
            }
          } else {
            setProcessingPayment(false);
            if (response.error.code === 'incorrect_number') {
              let creditInput = document.getElementById('cardNumber');
              creditInput.style.borderBottom = '5px solid red';
            } else if (response.error.code === 'invalid_expiry_year') {
              let expiryInput = document.getElementById('expDate');
              expiryInput.style.borderBottom = '5px solid red';
            } else {
              let creditInput = document.getElementById('cardNumber');
              creditInput.style.borderBottom = '';
            }
          }
        }
      );
    }
  }, [formInfo.cardNumber, formInfo.expDate, formInfo.cvv, props.cartTotal, props.cart, props.currentUser.accessToken, props.currentUser._id, setProcessingPayment]);

  React.useEffect(() => {
    createCharge();
    if (formInfo.emailAddress) {
      setUserCreated(true);
    }
  }, [props.currentUser._id, createCharge, formInfo.emailAddress]);

  const onSubmit = (data) => {
    // START LOADER!
    setProcessingPayment(true);
    setFormInfo({ ...data });

    // Deleting cc info for safety.
    delete data.cardNumber;
    delete data.expDate;
    delete data.cvv;

    // This method creates a new user and updates its attributes then returns the user while logging them in.
    // If everything passes validation and the charge is cleared. Then login user to the dashboard
    if (props.currentUser && props.currentUser._id) {
      console.log('user already created moving to charge card');
      createCharge();
    } else {
      props.submitRegister(data);
    }
  };

  return (
    <div>
      <div style={{ padding: '10px' }}>
        <img alt='' src={EditPageLogo} width="236" height="94" />
      </div>
      <div
        style={{
          fontFamily: 'Montserrat-Bold',
          fontSize: '36px',
          color: 'rgb(0, 61, 102)',
          padding: '15px',
        }}
      >
        Payment & Account Creation
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper className="backgroundAccent" elevation={0}>
          {processingPayment ? (
            <Paper
              style={{ paddingBottom: '20px', width: '100%' }}
              elevation={1}
              square
            >
              <div
                style={{
                  height: '600px',
                  textAlign: 'center',
                  paddingTop: '100px',
                  fontFamily: 'Montserrat-Bold',
                  fontSize: '27px',
                }}
              >
                Processing...
                <br />
                <br />
                <CircularProgress color="primary" />
              </div>
            </Paper>
          ) : (
            <div>
              <Paper
                style={{ paddingBottom: '20px', width: '100%' }}
                elevation={1}
                square
              >
                <Grid container>
                  <Grid
                    item
                    style={{ paddingTop: '20px', paddingLeft: '20px' }}
                    md={6}
                  >
                    <Paper square elevation={0}>
                      <div
                        style={{
                          fontSize: '27px',
                          color: 'rgb(0, 61, 102)',
                          fontFamily: 'Montserrat-Bold',
                          borderBottom: '1px solid rgb(0, 61, 102)',
                        }}
                      >
                        Billing address
                      </div>
                      <Grid
                        container
                        spacing={3}
                        style={{ width: '90%', paddingTop: '20px' }}
                      >
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="firstName"
                            name="firstName"
                            label="First name"
                            fullWidth
                            variant="filled"
                            autoComplete="fname"
                            defaultValue={formInfo.firstName}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                          {errors.firstName && 'First name is required'}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="lastName"
                            name="lastName"
                            label="Last name"
                            fullWidth
                            variant="filled"
                            autoComplete="lname"
                            defaultValue={formInfo.lastName}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                          {errors.lastName && 'Last name is required'}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="address1"
                            name="address1"
                            label="Address line 1"
                            fullWidth
                            variant="filled"
                            autoComplete="billing address-line1"
                            defaultValue={formInfo.address1}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                          {errors.address1 && 'Your address is required'}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="address2"
                            name="address2"
                            label="Address line 2"
                            fullWidth
                            variant="filled"
                            autoComplete="billing address-line2"
                            defaultValue={formInfo.address2}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            variant="filled"
                            placeholder="San Francisco"
                            autoComplete="billing address-level2"
                            defaultValue={formInfo.city}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                          {errors.city && 'Your city is required'}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="state"
                            name="state"
                            defaultValue={formInfo.state}
                            inputProps={{ maxLength: 2 }}
                            disabled={userCreated}
                            variant="filled"
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                            label="State/Province/Region"
                            fullWidth
                            placeholder="CA"
                          />
                          {errors.state && 'Your state is required'}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="zip"
                            name="zip"
                            label="Zip / Postal code"
                            fullWidth
                            variant="filled"
                            placeholder="11111"
                            autoComplete="billing postal-code"
                            defaultValue={formInfo.zip}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                          {errors.zip && 'Your zip is required'}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="country"
                            name="country"
                            label="Country"
                            fullWidth
                            variant="filled"
                            placeholder="US"
                            autoComplete="billing country"
                            defaultValue={formInfo.zip}
                            inputProps={{ maxLength: 2 }}
                            disabled={userCreated}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                          {errors.country && 'Your country is required'}
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    style={{ paddingTop: '20px', paddingLeft: '20px' }}
                    md={5}
                  >
                    <Paper square elevation={0}>
                      <div
                        style={{
                          fontSize: '27px',
                          color: 'rgb(0, 61, 102)',
                          fontFamily: 'Montserrat-Bold',
                          borderBottom: '1px solid rgb(0, 61, 102)',
                        }}
                      >
                        Credit Card
                      </div>
                      <Grid
                        container
                        spacing={3}
                        style={{ width: '100%', paddingTop: '20px' }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="cardName"
                            onChange={handleOnChangeForInput}
                            defaultValue={formInfo.cardName}
                            inputRef={register({ required: true })}
                            variant="filled"
                            label="Name on card"
                            fullWidth
                            placeholder="Name on card"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="cardNumber"
                            onChange={handleOnChangeForInput}
                            defaultValue={formInfo.cardNumber}
                            inputProps={{ maxLength: 16 }}
                            inputRef={register({
                              required: true,
                              min: 16,
                              max: 16,
                            })}
                            variant="filled"
                            id="cardNumber"
                            label="Card number"
                            fullWidth
                            placeholder="Credit Card Number"
                          />
                          {errors.cardNumber &&
                            'Number must be at least 16 numbers'}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="expDate"
                            onChange={handleOnChangeForInput}
                            defaultValue={formInfo.expDate}
                            inputProps={{ maxLength: 4 }}
                            id="expDate"
                            inputRef={register({ required: true })}
                            variant="filled"
                            label="Expiry date"
                            fullWidth
                            autoComplete="off"
                            placeholder="1221"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="cvv"
                            label="CVV"
                            variant="filled"
                            helperText="Last three digits on signature strip"
                            fullWidth
                            placeholder="123"
                            defaultValue={formInfo.cvv}
                            inputProps={{ maxLength: 4 }}
                            onChange={handleOnChangeForInput}
                            inputRef={register({ required: true })}
                          />
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          fontFamily: 'Montserrat-Bold',
                          fontSize: '18px',
                          clear: 'both',
                          textAlign: 'left',
                        }}
                      >
                        <img
                          width="194"
                          height="38"
                          src={CreditCards}
                          alt="Accepted Credit Cards"
                        />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{ marginTop: '20px', paddingBottom: '20px' }}
                elevation={1}
                square
              >
                <Grid container>
                  <Grid
                    item
                    style={{ paddingTop: '20px', paddingLeft: '20px' }}
                    md={6}
                  >
                    <Paper square elevation={0}>
                      <div
                        style={{
                          fontSize: '27px',
                          color: 'rgb(0, 61, 102)',
                          fontFamily: 'Montserrat-Bold',
                          borderBottom: '1px solid rgb(0, 61, 102)',
                        }}
                      >
                        Account
                      </div>
                      <Grid
                        container
                        spacing={3}
                        style={{ width: '90%', paddingTop: '20px' }}
                      >
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="emailAddress"
                            onChange={handleOnChangeForInput}
                            disabled={userCreated}
                            defaultValue={formInfo.emailAddress}
                            inputRef={register({ required: true })}
                            variant="filled"
                            id="emailAddress"
                            label="email address"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="password"
                            onChange={handleOnChangeForInput}
                            disabled={userCreated}
                            defaultValue={formInfo.password}
                            inputRef={register({ required: true })}
                            type="password"
                            id="password"
                            variant="filled"
                            label="password"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            required
                            name="confirmPassword"
                            onChange={handleOnChangeForInput}
                            disabled={userCreated}
                            defaultValue={formInfo.password}
                            inputRef={register({ required: true })}
                            type="password"
                            variant="filled"
                            label="confirm password"
                            fullWidth
                            autoComplete="off"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    style={{
                      paddingTop: '20px',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    md={6}
                  >
                    <Paper square elevation={0}>
                      <div
                        style={{
                          fontSize: '27px',
                          color: 'rgb(0, 61, 102)',
                          fontFamily: 'Montserrat-Bold',
                          borderBottom: '1px solid rgb(0, 61, 102)',
                        }}
                      >
                        Order
                      </div>
                      <div
                        style={{
                          fontFamily: 'Montserrat',
                          fontSize: '20px',
                          color: 'rgb(0, 61, 102)',
                          padding: '12px',
                        }}
                      >
                        {props.cart.map((cartItem, index) => (
                          <div key={index}>
                            <div style={{ float: 'left' }}>
                              <b>
                                {cartItem.updatePrice
                                  ? cartItem.years + ' years'
                                  : ''}
                              </b>{' '}
                              - {cartItem.domainName}
                            </div>
                            <div style={{ float: 'right' }}>
                              {cartItem.updatePrice ? (
                                <b>
                                  $
                                  {Number(cartItem.updatePrice)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </b>
                              ) : cartItem.premiumName === 'true' ? (
                                <b>
                                  $
                                  {Number(cartItem.premiumPrice)
                                    .toFixed(2)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                </b>
                              ) : (
                                <b>${cartItem.regularPrice}</b>
                              )}
                            </div>
                            <div style={{ clear: 'both' }}></div>
                          </div>
                        ))}
                      </div>
                      <div
                        style={{
                          fontFamily: 'Montserrat-Bold',
                          fontSize: '32px',
                          float: 'right',
                          padding: '10px',
                        }}
                      >
                        Total: &nbsp;
                        <b>
                          $
                          {props.cartTotal
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </b>
                      </div>
                      <div style={{ paddingTop: '10px', clear: 'both' }}>
                        <Button
                          style={{
                            backgroundColor: '#DD5E28',
                            color: '#FFF',
                            float: 'right',
                          }}
                          type="submit"
                          variant="contained"
                          size="large"
                          color="primary"
                          className="ctaButton"
                        >
                          PAY & SIGN UP
                        </Button>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          )}
        </Paper>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    email: state.auth.email,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submitRegister: (data) => {
      dispatch(submitRegisterDomainPayment(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PaymentDomain));
