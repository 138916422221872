import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

import secureServ from '../../../images/secureServ.jpg';
import onDemand from '../../../images/onDemand.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainWebBuilderHeadingMobile">
      <div className="webBuilderHeadingAlignMobile">
        <h1>
          <span> EditPage Web Hosting </span>
        </h1>
        <div className="webBuilderSubHeadingMobile">
          EditPage has reliable servers that keep your website secure and
          available.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Secure Servers</div>
        <div className="WTBdescriptionMobile">
          Making sure your website runs safely is what we do best. Try us out
          and see how simple it is to launch your secure website. Website
          hosting that works and is safe.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="170"
            src={secureServ}
            alt="Editpage website builder screenshot"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Handling Demand</div>
        <div className="WTBdescriptionMobile">
          When your website needs more bandwidth we autoscale your system to
          meet it. Don't worry about missing out on potential customers. We take
          care of that. Focus on building.
        </div>
        <br />
        <center>
          <img
            width="280"
            height="210"
            src={onDemand}
            alt="Chef wearing glasses"
          />
        </center>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
