import React, { Component } from 'react';

import DesktopResponsive from './responsive/Marketing/DesktopResponsive';
import MobileResponsive from './responsive/Marketing/MobileResponsive';

class Marketing extends Component {
  render() {
    return (
      <div>
        <DesktopResponsive history={this.props.history} />
        <MobileResponsive history={this.props.history} />
      </div>
    );
  }
}

export default Marketing;
