import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

import webBuilderLaptop from '../../../images/webBuilderLaptop.png';
import manWearingEyeglasses from '../../../images/man-wearing-eyeglasses.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainWebBuilderHeadingMobile">
      <div className="webBuilderHeadingAlignMobile">
        <h1>
          <span> EditPage Website Builder </span>
        </h1>
        <div className="webBuilderSubHeadingMobile">
          EditPage has an intuitive website builder made for anyone.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Intuitive Features</div>
        <div className="WTBdescriptionMobile">
          Building a website has never been this easy. Our pre-built templates
          are full feature websites ready to go. Update the text, change the
          color, add some images then publish.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="190"
            src={webBuilderLaptop}
            alt="Editpage website builder screenshot"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Business Templates</div>
        <div className="WTBdescriptionMobile">
          Getting your business website launched is simple. From selling
          products to restaurant menus we got a template for that. If we don't
          have a template you need, just let us know and we will build one for
          you.
        </div>
        <br />
        <center>
          <img
            width="270"
            height="310"
            src={manWearingEyeglasses}
            alt="Chef wearing glasses"
          />
        </center>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
