import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import logo from '../../images/logo.png';

const Success = () => {
  return (
    <div>
      <br />
      <center>
        <h5 className="forgotPass">Success! Check your email</h5>
      </center>
      <br />
    </div>
  );
};

const LoadForm = ({ updatePassword }) => {
  return (
    <div>
      <h5 className="forgotPass">Reset Password</h5>
      <br />
      <Form onSubmit={updatePassword}>
        <Form.Group>
          <Form.Label><b>Your email address</b></Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder="Enter email"
            autoComplete="off"
          />
          <Form.Text className="text-muted">
            Enter your email and we will send you a email with more information
          </Form.Text>
        </Form.Group>

        <div className="forgotPassFooter">
          <button className="forgotPassBtn">
            Reset Password
          </button>
        </div>
      </Form>
    </div>
  );
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };
    this.updatePassword = this.updatePassword.bind(this);
  }

  updatePassword(event) {
    event.preventDefault();
    let email = event.currentTarget[0].value;
    fetch(process.env.REACT_APP_BACKEND_URL + '/user/forgot', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email }),
      mode: 'cors',
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    });
    this.setState({ submitted: true });
  }

  render() {
    return (
      <div className="forgotPassBanner">
        <div className="logoWiz spacerFromLogo">
          <img width="180" height="74" src={logo} alt="" />
        </div>
        <div className="forgotPassContainer">
          {this.state.submitted ? (
            <Success />
          ) : (
            <LoadForm updatePassword={this.updatePassword} />
          )}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
