import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Editor from '../../../images/marketing/editor.png';
import DomainResults from '../../presentation/DomainResults/DomainResults';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({
  open,
  domainName,
  handleKeyDown,
  handleDomainOpen,
  handleOnChange,
}) => (
  <Mobile>
    {open ? (
      <DomainResults domainName={domainName} />
    ) : (
    <div>
      <section className="section-1" style={{ marginBottom: '50px' }}>
        <div
          className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
        >
          <img className="circle-1 img-fluid" src={Shape} alt="circle" />
          <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
          <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
          <h1 className="fw-bold section1-head1-mobile">
            We make it simple to build a website<span style={{ color: 'orange' }}>.</span>
          </h1>
          <p className="section1-pera-mobile" style={{ color: 'gray' }}>
            Create a website in just three clicks. Unique, simple <br />
            and friendly. It's that easy
          </p>

          <div className="search-mobile d-flex">
            <div className="input-group">
              <input
                type="text"
                onKeyDown={handleKeyDown}
                onChange={handleOnChange}
                className="form-control mr-r-5 find-input-mobile"
                placeholder="Find your domain"
                autoComplete="off"
                aria-label="Domain Search"
                aria-describedby="button-addon2"
                value={domainName.name}
              />
              <button
                className="btn-primary demand-btn-mobile"
                type="button"
                onClick={handleDomainOpen}
                id="button-addon2"
              >
                Search
              </button>
            </div>
          </div>
          <br />
          <br />
          <h1 className="fw-bold section1-head2-mobile">
            Intuitive Web builder<span style={{ color: 'orange' }}>.</span>
          </h1>
          <img className="bigImg-1 img-fluid" src={Editor} alt="" />
        </div>
      </section>
    </div>
  )}
  </Mobile>
);

export default MobileResponsive;
