import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';

import styles from './NavBarNotification.module.css';

const useStyles = makeStyles(() => ({
  customBadge: {
    backgroundColor: '#e77343',
    color: 'white',
  },
}));

const NavBarNotifications = (props) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const { items, type } = props;

  const handleClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = (event) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !event.target.closest('.MuiIconButton-root')
    ) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={items.length} classes={{ badge: classes.customBadge }}>
          <NotificationsIcon style={{ color: '#92929D' }} />
        </Badge>
      </IconButton>
      <div className={`${styles.listPopover} ${isModalOpen ? styles.open : ''}`} ref={modalRef}>
        <div className={styles.listHeader}>
          <h2 className={styles.listTitle}>Notifications</h2>
          <span className={styles.markAsRead}>Mark all as read</span>
        </div>

        <ul className={styles.listNotifications}>
          {items.map((notification) => {
            const { id, recent, subject, title, body } = notification;
            const displayText = type === 'email' ? subject : title;

            return (
              <li key={id} className={`${styles.itemNotification} ${recent ? styles.recent : ''}`}>
                <div className={styles.itemHeader}>
                  <span className={styles.textPrimary}>{displayText}</span>
                  <span className={styles.itemTime}>5 h ago</span>
                </div>
                <p className={styles.textSecondary}>{body.slice(0, 50) + '...'}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default NavBarNotifications;