import React, { Component } from 'react';

import DesktopResponsive from './responsive/Contact/DesktopResponsive';
import MobileResponsive from './responsive/Contact/MobileResponsive';

class Contact extends Component {
  constructor() {
    super();

    this.state = {
      submitted: false,
      fields: {},
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.contactSubmit = this.contactSubmit.bind(this);
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // First Name
    if (!fields['firstName']) {
      formIsValid = false;
      errors['firstName'] = 'Cannot be empty';
    }

    if (typeof fields['firstName'] !== 'undefined') {
      if (!fields['firstName'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors['firstName'] = 'Only letters';
      }
    }

    // Last Name
    if (!fields['lastName']) {
      formIsValid = false;
      errors['lastName'] = 'Cannot be empty';
    }

    if (typeof fields['lastName'] !== 'undefined') {
      if (!fields['lastName'].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors['lastName'] = 'Only letters';
      }
    }

    //Email
    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = 'Cannot be empty';
    }

    if (typeof fields['email'] !== 'undefined') {
      let lastAtPos = fields['email'].lastIndexOf('@');
      let lastDotPos = fields['email'].lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields['email'].indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          fields['email'].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors['email'] = 'Email is not valid';
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  contactSubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      this.sendEmail();
      this.setState({ submitted: true });
    } else {
      alert('Form has errors.');
    }
  }

  sendEmail() {
    let contactData = this.state.fields;
    fetch(process.env.REACT_APP_BACKEND_URL + '/email/contact', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData),
      mode: 'cors',
    }).then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    });
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  render() {
    return (
      <div>
        <DesktopResponsive
          history={this.props.history}
          fields={this.state.fields}
          contactSubmit={this.contactSubmit}
          handleChange={this.handleChange}
          submitted={this.state.submitted}
        />
        <MobileResponsive
          history={this.props.history}
          fields={this.state.fields}
          contactSubmit={this.contactSubmit}
          handleChange={this.handleChange}
          submitted={this.state.submitted}
        />
      </div>
    );
  }
}

export default Contact;
