import actionTypes from '../constants/actionTypes';

export function toggleCart() {
  return {
    type: actionTypes.TOGGLE_CART,
  };
}

export function toggleCartFilled() {
  return {
    type: actionTypes.TOGGLE_CART_FILLED,
  };
}

export function totalAmount(results) {
  return {
    type: actionTypes.TOTAL_AMOUNT,
    results: results
  };
}