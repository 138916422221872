import actionTypes from '../constants/actionTypes';
import { history } from '../helpers/history';

function userLoggedIn(email) {
  return {
    type: actionTypes.USER_REGISTERED,
    email: email,
  };
}

function newUserLoggedIn(user) {
  return {
    type: actionTypes.USER_REGISTERED_AND_LOGGED_IN,
    results: user,
  };
}

function authError(message) {
  return {
    type: actionTypes.USER_AUTH_ERROR,
    message: message,
  };
}

function getCurrentUsersData(results) {
  return {
    type: actionTypes.GET_CURRENT_USER,
    results: results.currentUser,
  };
}

function logout() {
  return {
    type: actionTypes.USER_LOGOUT,
  };
}

export function submitLogin(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/user/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('email', data.data.email);
        localStorage.setItem('token', data.data.tokenID);

        dispatch(userLoggedIn(data.data.email));
        history.push('/dashboard'); // redirect to dashboard
        // if (data.data.email === 'scott@editpage.com') {
        //   history.push('/dashboard'); // redirect to dashboard
        // } else {
        //   history.push('/beta'); // redirect to beta
        // }
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        dispatch(authError(message));
      });
  };
}

export function adminLoginAs(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/user/login/user', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('email', data.data.email);
        localStorage.setItem('token', data.data.tokenID);

        dispatch(userLoggedIn(data.data.email));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        dispatch(authError(message));
      });
  };
}

export function submitRegisterDomainPayment(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/user/signup/domain', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('email', data.data.email);
        localStorage.setItem('token', data.data.accessToken);

        dispatch(newUserLoggedIn(data.data));
      })
      .catch((e) => console.log(e));
  };
}

export function submitRegister(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/user/signup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('email', data.data.email);
        localStorage.setItem('token', data.data.tokenID);

        dispatch(userLoggedIn(data.data.email));
        history.push('/beta'); // redirect to dashboard
      })
      .catch((e) => console.log(e));
  };
}

export function getCurrentUser(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_BACKEND_URL + '/user', {
        method: 'GET',
        headers: {
          'x-access-token': data.tokenID,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          dispatch(getCurrentUsersData(data.data));
          resolve(data); // Resolve the Promise with the data
        })
        .catch((event) => {
          let message = event.message;
          if (message === 'Internal Server Error') {
            message = 'Oops, looks like our server is down. Sorry.';
            dispatch(logoutUser());
            history.push('/');
          } else if (message === 'Unauthorized') {
            message = 'Please check your email and password';
          }
          dispatch(authError(message));
          reject(event); // Reject the Promise with the error event
        });
    });
  };
}


export function logoutUser() {
  return (dispatch) => {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    dispatch(logout());
  };
}
