import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './stores/store';
import './index.css';
import App from './App';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/browser';
import registerServiceWorker from './registerServiceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

Sentry.init({
  dsn: 'https://823e827b08dc46778ec2d1d621729683@sentry.io/1766346',
});

const rootElement = document.getElementById('root');
render(
  <Provider store={store}>
    <SnackbarProvider maxSnack={3} hideIconVariant>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </SnackbarProvider>
  </Provider>,
  rootElement
);
registerServiceWorker();
