import React, { Component } from 'react';

import DesktopResponsive from './responsive/EmailHosting/DesktopResponsive';
import MobileResponsive from './responsive/EmailHosting/MobileResponsive';

class EmailHosting extends Component {
  render() {
    return (
      <div>
        <DesktopResponsive history={this.props.history} />
        <MobileResponsive history={this.props.history} />
      </div>
    );
  }
}

export default EmailHosting;
