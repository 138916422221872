import React, { Component } from 'react';
import logoWhite from '../images/footerLogo.png';
import BumperStickers from '../images/marketing/bumperStickers.png';

class Footer extends Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <footer className="footer">
          <div className="footer-logo-wrap">
            <a href="/">
              <img className="footer-logo" src={logoWhite} alt="" />
            </a>
            <p className="footer-pera">
              EditPage is a simple, easy and affordable website builder. We make
              creating your website attainable and convenient.
            </p>
          </div>

          <div className="item-wrap">
            <div className="product">
              <h1 className="item-wrap-h1">PRODUCT</h1>
              <div className="d-flex flex-column">
                <a className="footer-item" href="/features">
                  Features
                </a>
                <a className="footer-item" href="/webbuilder">
                  Website Builder
                </a>
                <a className="footer-item" href="/webhosting">
                  Web Hosting
                </a>
                <a className="footer-item" href="/emailhosting">
                  Email Hosting
                </a>
                <a className="footer-item" href="/marketing">
                  Marketing
                </a>
                <a className="footer-item" href="/onlinestore">
                  Online Store
                </a>
                <a className="footer-item" href="/domain">
                  Domain Registration
                </a>
              </div>
            </div>
            <div className="company">
              <h1 className="item-wrap-h1">COMPANY</h1>
              <div className="d-flex flex-column">
                <a className="footer-item" href="/about">
                  About EditPage
                </a>
                <a className="footer-item" href="/press">
                  Press
                </a>
                <a className="footer-item" href="/jobs">
                  EditPage Jobs
                </a>
                <a className="footer-item" href="/terms">
                  Terms of Use
                </a>
                <a className="footer-item" href="/contact">
                  Contact Us{' '}
                </a>
                <a className="footer-item" href="/privacy">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="social">
              <h1 className="item-wrap-h1">SOCIAL</h1>
              <div className="d-flex flex-column">
                <a
                  className="footer-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/editpagehq/"
                >
                  Instagram
                </a>
                <a
                  className="footer-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/editpagehq"
                >
                  Facebook
                </a>
                <a
                  className="footer-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/editpagehq"
                >
                  Twitter
                </a>
                <a
                  className="footer-item"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.youtube.com/channel/UCNh64lJCWeEevmYcsX8a0aw"
                >
                  YouTube
                </a>
              </div>
            </div>
            <div className="support">
              <h1 className="item-wrap-h1">SUPPORT</h1>
              <div className="d-flex flex-column">
                <a className="footer-item" href="/support">
                  Support Center
                </a>
              </div>
            </div>
          </div>
        </footer>
        <div className="bottom">
          <p className="copyRight">
            © {currentYear} Copyright.{' '}
            <a href="https://www.editpage.com">
              <b>EditPage.com</b>
            </a>
          </p>
          <img className="bottom-img" src={BumperStickers} alt="" />
        </div>
      </div>
    );
  }
}

export default Footer;
