import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import DesktopResponsive from './responsive/WebBuilder/DesktopResponsive';
import MobileResponsive from './responsive/WebBuilder/MobileResponsive';

class WebBuilder extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Intuitive Website Builder | EditPage</title>
          <meta
            content="EditPage is a simple, easy and affordable website builder. We make creating your website attainable and convenient."
            name="description"
          />
          <meta content="EditPage" name="generator" />
          <link rel="canonical" href="https://www.editpage.com/webbuilder" />
        </Helmet>
        <DesktopResponsive history={this.props.history} />
        <MobileResponsive history={this.props.history} />
      </div>
    );
  }
}

export default WebBuilder;
