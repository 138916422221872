import React from 'react';
import { submitRegister } from '../../actions/authActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import logo from '../../images/logo.png';

function Copyright() {
  return (
    <p
      style={{ width: '532px', margin: '0 auto' }}
      className="footer-link-cr text-md-left"
    >
      © {new Date().getFullYear()} Copyright: &nbsp;
      <a href="http://www.editpage.com" className="footer-link-cr">
        <strong>EditPage.com</strong>
      </a>
    </p>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Signup(props) {
  const [details, setDetails] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    emailValid: false,
    passwordValid: false,
    formValid: false,
  });
  const classes = useStyles();

  const validateField = (name, value) => {
    // First name present validation
    let firstNameInput = document.getElementById('firstName');
    if (name === 'firstName' && value.length > 3) {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: false,
      }));
      firstNameInput.style.borderBottom = '';
    } else if (name === 'firstName') {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: true,
      }));
      firstNameInput.style.borderBottom = '5px solid red';
    }

    // Last name present validation
    let lastNameInput = document.getElementById('lastName');
    if (name === 'lastName' && value.length > 3) {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: false,
      }));
      lastNameInput.style.borderBottom = '';
    } else if (name === 'lastName') {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: true,
      }));
      lastNameInput.style.borderBottom = '5px solid red';
    }

    // Email validation
    let emailInput = document.getElementById('email');
    if (name === 'email' && validateEmail(value)) {
      checkIfEmailAlreadyExisits({ email: value });
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: false,
      }));
      emailInput.style.borderBottom = '';
    } else if (name === 'email') {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: true,
      }));
      emailInput.style.borderBottom = '5px solid red';
    }

    // Password present validation
    let passwordInput = document.getElementById('password');
    if (name === 'password' && value.length > 5) {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: false,
      }));
      passwordInput.style.borderBottom = '';
    } else if (name === 'password') {
      setDetails(prevInputState => ({
        ...prevInputState,
        formValid: true,
      }));
      passwordInput.style.borderBottom = '5px solid red';
    }
  };

  const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const checkIfEmailAlreadyExisits = email => {
    let req = null;
    req = req + 1;
    if (req === 1) {
      fetch(process.env.REACT_APP_BACKEND_URL + '/users/checkEmail', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(email),
        mode: 'cors',
      })
        .then(response => {
          if (!response.ok) {
            console.log('Heres our error', response.statusText);
          }
          return response.json();
        })
        .then(data => {
          let emailInput = document.getElementById('email');
          if (data.data) {
            emailInput.style.borderBottom = '';
            setDetails(prevInputState => ({
              ...prevInputState,
              emailValid: false,
              formValid: false,
            }));
          } else {
            setDetails(prevInputState => ({
              ...prevInputState,
              emailValid: true,
              formValid: true,
            }));
            emailInput.style.borderBottom = '5px solid red';
          }
        });
    }
  };

  const handleUserInput = e => {
    let { name, value } = e.target;
    validateField(name, value);
    setDetails(prevInputState => ({
      ...prevInputState,
      [name]: value,
    }));
  };

  const register = e => {
    e.preventDefault();
    const loginCreds = {
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
      password: details.password,
    };
    if (
      details.firstName &&
      details.lastName &&
      details.email &&
      details.password
    ) {
      props.register(loginCreds);
    }
  };

  return (
    <div>
      <CssBaseline />
      <div className={classes.paper}>
        <div className="logoWiz spacerFromLogo">
          <img width="180" height="74" src={logo} alt="" />
        </div>
        <div noValidate className="signupForm">
          <h3
            style={{
              fontFamily: 'Montserrat',
              fontWeight: '600',
              color: '#00003B',
            }}
          >
            Sign up
          </h3>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                onChange={handleUserInput}
                id="firstName"
                label="First Name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleUserInput}
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleUserInput}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
            {details.emailValid ? (
              <div
                id="error_message"
                style={{
                  color: 'red',
                  paddingLeft: '110px',
                  fontWeight: 'bold',
                }}
              >
                You've already signed up with that email
              </div>
            ) : (
              ''
            )}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={handleUserInput}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
            <Grid item xs={12} />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={register}
            disabled={details.formValid}
            style={{ height: '68px', backgroundColor: '#003D66' }}
          >
            <b>Sign Up</b>
          </Button>
          <Grid container justify="flex-end">
            <Grid item style={{ paddingTop: '25px' }}>
              <Link
                href="https://www.editpage.com/login"
                style={{ color: '#00003B' }}
                variant="body2"
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    email: state.auth.email,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    register: data => {
      dispatch(submitRegister(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Signup));
