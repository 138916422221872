import actionTypes from '../constants/actionTypes';

function wordSuggestions(results) {
  return {
    type: actionTypes.WORD_SUGGESTIONS,
    results: results,
  };
}

function domainNameCheck(results) {
  return {
    type: actionTypes.DOMAIN_NAME_CHECK,
    results: results,
  };
}

export function domainLookup(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/domain/lookup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // localStorage.setItem('email', data.data.email);
        // localStorage.setItem('token', data.data.tokenID);
        //
        // dispatch(userLoggedIn(data.data.email));
        // history.push('/main');
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

export function toggleDomainResults() {
  return {
    type: actionTypes.TOGGLE_DOMAIN_RESULTS,
  };
}

export function domainCheck(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/domain/check', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(domainNameCheck(data.data));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

export function wordLookup(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/domain/wordlookup', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);

        // localStorage.setItem('email', data.data.email);
        // localStorage.setItem('token', data.data.tokenID);
        //
        dispatch(wordSuggestions(data.data));
        // history.push('/main');
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}