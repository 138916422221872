import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import PrivateRoute from './PrivateRoute';
import { Route, Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './helpers/history';
import Layout from './components/layouts/Layout';
import Home from './components/Home';
import Features from './components/Features';
import Pricing from './components/containers/Pricing';
import Support from './components/Support';
import Login from './components/presentation/Login';
import Signup from './components/presentation/Signup';
import Domain from './components/DomainRegistration';
import Publish from './components/presentation/Publish';
import PublicWebsites from './components/presentation/PublicWebsites';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import WebBuilder from './components/WebBuilder';
import WebHosting from './components/WebHosting';
import EmailHosting from './components/EmailHosting';
import Marketing from './components/Marketing';
import OnlineStore from './components/OnlineStore';
import AboutEditPage from './components/AboutEditPage';
import Press from './components/Press';
import Jobs from './components/Jobs';
import Contact from './components/Contact';
import ForgotPassword from './components/presentation/ForgotPassword';
import UpdatePassword from './components/presentation/UpdatePassword';
import NotFound from './components/NotFound';
import CircularProgress from '@material-ui/core/CircularProgress';

// Lazy load
const Main = React.lazy(() => import('./components/presentation/main/Main'));
const Dashboard = React.lazy(() =>
  import('./components/presentation/Dashboard/Dashboard')
);
const Editor = React.lazy(() =>
  import('./components/presentation/Editor/Editor')
);
const Analytics = React.lazy(() =>
  import('./components/presentation/Analytics/Analytics')
);
const RegisterDomain = React.lazy(() =>
  import('./components/presentation/RegisterDomain/RegisterDomain')
);
const Storage = React.lazy(() =>
  import('./components/presentation/Storage/Storage')
);
const BoostTraffic = React.lazy(() =>
  import('./components/presentation/BoostTraffic/BoostTraffic')
);
const Email = React.lazy(() =>
  import('./components/presentation/Email/Email')
);
const Store = React.lazy(() =>
  import('./components/presentation/Store/Store')
);
const DomainManager = React.lazy(() =>
  import('./components/presentation/DomainManager')
);
const Payment = React.lazy(() => import('./components/presentation/Payment'));
const Checkout = React.lazy(() => import('./components/payment/Checkout'));
const UserAccount = React.lazy(() =>
  import('./components/presentation/Account/Account')
);
const AdminDashboard = React.lazy(() =>
  import('./components/admin/AdminDashboard')
);
const Beta = React.lazy(() => import('./components/presentation/Beta'));

// Our loading screen
const Loader = () => {
  return (
    <div>
      <center>
        <div
          style={{ height: '400px', textAlign: 'center', paddingTop: '300px' }}
        >
          <CircularProgress color="primary" />
        </div>
      </center>
    </div>
  );
};
const App = ({ currentUser, loggedIn }) => (
  <Router history={history}>
    <Layout loggedIn={loggedIn} currentUser={currentUser}>
      <Switch>
        <Route exact path="/" component={props => <Home {...props} />} />
        <Route path="/features" component={Features} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/support" component={Support} />
        <Route path="/login" component={props => <Login {...props} />} />
        <Route path="/signup" component={props => <Signup {...props} />} />
        <Route path="/domain" component={props => <Domain {...props} />} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/updatepassword" component={UpdatePassword} />
        <Route path="/published" component={props => <Publish {...props} />} />
        <Route path="/terms" component={Terms} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/webbuilder" component={WebBuilder} />
        <Route path="/webhosting" component={WebHosting} />
        <Route path="/emailhosting" component={EmailHosting} />
        <Route path="/marketing" component={Marketing} />
        <Route path="/onlinestore" component={OnlineStore} />
        <Route path="/about" component={AboutEditPage} />
        <Route path="/press" component={Press} />
        <Route path="/jobs" component={Jobs} />
        <Route path="/contact" component={Contact} />
        <Suspense fallback={Loader()}>
          <PrivateRoute
            exact
            path="/editor/:id"
            component={Editor}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/main/:id"
            component={Main}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/editor"
            component={Editor}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/analytics"
            component={Analytics}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/register"
            component={RegisterDomain}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/storage"
            component={Storage}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/traffic"
            component={BoostTraffic}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/email"
            component={Email}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/store"
            component={Store}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/manager"
            component={DomainManager}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/payment"
            component={Payment}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/checkout"
            component={Checkout}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/account"
            component={UserAccount}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/admin/dashboard"
            component={AdminDashboard}
            loggedIn={loggedIn}
          />
          <PrivateRoute
            exact
            path="/beta"
            component={Beta}
            loggedIn={loggedIn}
          />
        </Suspense>
        <Route exact path="/:name" component={props => <PublicWebsites {...props} />} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  </Router>
);

const { bool, object } = PropTypes;

App.propTypes = {
  loggedIn: bool.isRequired,
  currentUser: object.isRequired,
};

const mapState = state => ({
  loggedIn: state.auth.loggedIn,
  currentUser: state.auth.currentUser,
});

export default connect(mapState)(App);
