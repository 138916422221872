import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

import onlineStoreLaptop from '../../../images/onlineStoreLaptop.png';
import onlineStoreSellingLaptop from '../../../images/onlineStoreSellingLaptop.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainWebBuilderHeadingMobile">
      <div className="webBuilderHeadingAlignMobile">
        <h1>
          <span> EditPage Online Store </span>
        </h1>
        <div className="webBuilderSubHeadingMobile">
          EditPage offers a simple ecommerce solution to get you into business.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">All the tools you need</div>
        <div className="WTBdescriptionMobile">
          Don't miss a sale because of some problem. Our system will help you
          sell more product faster and better. Track your revenue and make sure
          you're making the sales to succeed.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="190"
            src={onlineStoreLaptop}
            alt="Editpage website builder screenshot"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">eCommerce Templates</div>
        <div className="WTBdescriptionMobile">
          We make it simple to setup your store so you can focus on the business
          instead of the tech. If we don't have a template you need, just let us
          know and we will build one for you.
        </div>
        <br />
        <center>
          <img
            width="310"
            height="190"
            src={onlineStoreSellingLaptop}
            alt="Chef wearing glasses"
          />
        </center>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
