import constants from '../constants/actionTypes';

var initialState = {
  domainName: '',
  domainsLoaded: { isLoaded: false },
  domainList: [],
  suggestions: '',
  isDomainResultsOpen: false
};

export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.WORD_SUGGESTIONS:
      updated['suggestions'] = action.results;
      return updated;

    case constants.DOMAIN_NAME_CHECK:
      updated['domainList'] = action.results;
      updated['domainsLoaded']['isLoaded'] = true;
      return updated;

    case constants.TOGGLE_DOMAIN_RESULTS:
      return {
        ...state,
        isDomainResultsOpen: !state.isDomainResultsOpen,
      };

    default:
      return state;
  }
};