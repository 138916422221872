import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../actions/authActions';
import { getWebsite } from '../../actions/websiteActions';

class Publish extends Component {
  constructor() {
    super();

    this.state = {};

    this.filterWebContentforPublicView =
      this.filterWebContentforPublicView.bind(this);
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.props.getCurrentUser({ tokenID: localStorage.getItem('token') });
      this.props.getWebsite({
        tokenID: localStorage.getItem('token'),
        websiteId: window.location.pathname.substr(
          window.location.pathname.lastIndexOf('/') + 1
        ),
      });
    }
  }

  filterWebContentforPublicView() {
    let filteredContent;
    // We should put any badwords or naughty photos here for sanitization.
    if (this.props.website.html) {
      filteredContent = this.props.website.html
        .replace(/contenteditable=/g, '')
        .replace(/border-radius/, '');
    }
    return filteredContent;
  }

  render() {
    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: this.filterWebContentforPublicView(),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    currentUser: state.auth.currentUser,
    website: state.website.website,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentUser: (data) => {
      dispatch(getCurrentUser(data));
    },
    getWebsite: (data) => {
      dispatch(getWebsite(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
