import constants from '../constants/actionTypes';
import { emailData } from '../components/presentation/Email/EmailData';

var initialState = {
  emailsLoaded: { isLoaded: false },
  emailMessage: {},
  emailAccount: {
    inboxMail: [],
    sentMail: emailData.slice(0, 7),
    spamMail: emailData.slice(0, 3),
    drafts: emailData.slice(0, 2),
  },
};


export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.GET_EMAIL_BODY:
      updated['emailMessage'] = action.results;
      return updated;
    case constants.GET_EMAIL_ACCOUNT:
      updated['emailAccount'] = action.results;
      updated['emailsLoaded']['isLoaded'] = true;
      return updated;

      case constants.TOGGLE_STARRED_EMAIL:
        const { uid, category } = action;

        if (updated.emailAccount.hasOwnProperty(category)) {
          updated.emailAccount[category] = state.emailAccount[category].map(
            (email) => {
              if (email.uid === uid) {
                return {
                  ...email,
                  starred: !email.starred,
                };
              }
              return email;
            }
          );
        }
        return updated;

        case constants.DELETE_EMAIL:
          const { uid: deleteId, category: deleteCategory } = action;

          if (updated.emailAccount.hasOwnProperty(deleteCategory)) {
            updated.emailAccount[deleteCategory] = state.emailAccount[deleteCategory].filter(
              (email) => email.uid !== deleteId
            );
          }
          return updated;

    default:
      return state;
  }
};
