import React, { Component } from 'react';
import { submitLogin } from '../../actions/authActions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import DesktopResponsive from '../responsive/Login/DesktopResponsive';
import MobileResponsive from '../responsive/Login/MobileResponsive';

class Login extends Component {
  constructor() {
    super();

    this.state = {
      submitted: false,
      details: {},
    };

    this.login = this.login.bind(this);
    this.updateDetails = this.updateDetails.bind(this);
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.props.history.push('/main');
    }
  }

  updateDetails(event) {
    let updateDetails = Object.assign({}, this.state.details);

    updateDetails[event.target.id] = event.target.value;
    this.setState({
      details: updateDetails,
    });
  }

  login(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    if (this.state.details.email && this.state.details.password) {
      this.props.login(this.state.details);
    }
  }

  render() {
    return (
      <div>
        <DesktopResponsive
          login={this.login}
          authError={this.props.authError}
          authErrorMessage={this.props.authErrorMessage}
          submitted={this.state.details.submitted}
          email={this.state.details.email}
          updateDetails={this.updateDetails}
        />

        <MobileResponsive
          login={this.login}
          authError={this.props.authError}
          authErrorMessage={this.props.authErrorMessage}
          submitted={this.state.details.submitted}
          email={this.state.details.email}
          updateDetails={this.updateDetails}
          history={this.props.history}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    currentUser: state.auth.currentUser,
    authError: state.auth.authError,
    authErrorMessage: state.auth.authErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => {
      dispatch(submitLogin(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
