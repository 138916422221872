import constants from '../constants/actionTypes';

var initialState = {
  loggedIn: localStorage.getItem('token') ? true : false,
  email: localStorage.getItem('email') ? localStorage.getItem('email') : '',
  authError: {},
  currentUser: {},
};

export default (state = initialState, action) => {
  var updated = Object.assign({}, state);

  switch (action.type) {
    case constants.USER_REGISTERED:
      updated['loggedIn'] = true;
      updated['email'] = action.email;
      return updated;

    case constants.USER_LOGGEDIN:
      updated['loggedIn'] = true;
      updated['email'] = action.email;
      return updated;

    case constants.USER_LOGOUT:
      updated['loggedIn'] = false;
      updated['email'] = '';
      return updated;

    case constants.USER_AUTH_ERROR:
      updated['authError'] = true;
      updated['authErrorMessage'] = action.message;
      return updated;

    case constants.GET_CURRENT_USER:
      updated['currentUser'] = action.results;
      return updated;

    case constants.USER_REGISTERED_AND_LOGGED_IN:
      updated['loggedIn'] = true;
      updated['rollInUser'] = true;
      updated['currentUser'] = action.results;
      return updated;

    default:
      return state;
  }
};
