import actionTypes from '../constants/actionTypes';

function getWebsitesData(results) {
  return {
    type: actionTypes.GET_WEBSITES,
    results: results,
  };
}

function getUserWebsitesData(results) {
  return {
    type: actionTypes.GET_USER_WEBSITES,
    results: results,
  };
}

function getWebsiteData(results) {
  return {
    type: actionTypes.GET_WEBSITE,
    results: results,
  };
}

function getPublicWebsiteData(results) {
  return {
    type: actionTypes.GET_PUBLIC_WEBSITE,
    results: results,
  };
}

function createWebsiteData(results) {
  return {
    type: actionTypes.CREATE_WEBSITE,
    results: results,
  };
}

// function saveWebsiteData(results) {
//   return {
//     type: actionTypes.SAVE_WEBSITE,
//     results: results,
//   };
// }

function deleteWebsiteData(results) {
  return {
    type: actionTypes.DELETE_WEBSITE,
    results: results,
  };
}

// Get all of a user websites
export function getUserWebsites(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/website/user', {
      method: 'GET',
      headers: {
        'x-access-token': data.tokenID,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(getUserWebsitesData(data.data));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

// Get all websites
export function getWebsites(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/website', {
      method: 'GET',
      headers: {
        'x-access-token': data.tokenID,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(getWebsitesData(data.data));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

// Get one website
export function getWebsite(data) {
  return (dispatch) => {
    return fetch(
      process.env.REACT_APP_BACKEND_URL + '/website/' + data.websiteId,
      {
        method: 'GET',
        headers: {
          'x-access-token': data.tokenID,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(getWebsiteData(data.data.website));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

// Get website for public view
export function getPublicWebsite(data) {
  return (dispatch) => {
    return fetch(
      process.env.REACT_APP_BACKEND_URL +
        '/website/publicWebsite/' +
        data.websiteName,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(getPublicWebsiteData(data.data.website));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

export function updateWebsite(data) {
  return (dispatch) => {
    return fetch(
      process.env.REACT_APP_BACKEND_URL + '/website/update/' + data.websiteId,
      {
        method: 'POST',
        headers: {
          'x-access-token': data.tokenID,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        mode: 'cors',
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        //dispatch(getWebsitesData(data.data));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

export function updateWebsiteHTML(data) {
  return (dispatch) => {
    return fetch(
      process.env.REACT_APP_BACKEND_URL +
        '/website/update/html/' +
        data.websiteId,
      {
        method: 'POST',
        headers: {
          'x-access-token': data.tokenID,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        mode: 'cors',
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

export function createWebsite(data) {
  return (dispatch) => {
    return fetch(process.env.REACT_APP_BACKEND_URL + '/website/create', {
      method: 'POST',
      headers: {
        'x-access-token': data.tokenID,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ websiteName: data.websiteName }),
      mode: 'cors',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        data.data.newWebsite = true;
        dispatch(createWebsiteData(data.data));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}

export function deleteWebsite(data) {
  return (dispatch) => {
    return fetch(
      process.env.REACT_APP_BACKEND_URL + '/website/delete/' + data.websiteId,
      {
        method: 'POST',
        headers: {
          'x-access-token': data.tokenID,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        dispatch(deleteWebsiteData(data.data));
      })
      .catch((event) => {
        let message = event.message;
        if (message === 'Failed to fetch') {
          message = 'Oops, looks like our server is down. Sorry.';
        } else if (message === 'Unauthorized') {
          message = 'Please check your email and password';
        }
        //dispatch(authError(message));
      });
  };
}
