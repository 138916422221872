import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Button from '@material-ui/core/Button';

import emailLaptop from '../../../images/emailLaptop.png';
import emailCartoon from '../../../images/emailCartoon.png';

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const MobileResponsive = ({ history }) => (
  <Mobile>
    <div className="mainWebBuilderHeadingMobile">
      <div className="webBuilderHeadingAlignMobile">
        <h1>
          <span> EditPage Email Hosting </span>
        </h1>
        <div className="webBuilderSubHeadingMobile">
          EditPage offers email as an option together with your website.
        </div>
        <Button
          style={{ backgroundColor: '#DD5E28', color: '#fff' }}
          variant="contained"
          size="large"
          className="ctaButton"
          onClick={() => {
            history.push('/signup');
          }}
        >
          Start Building
        </Button>
      </div>
    </div>
    <div className="featuresBodyMobile">
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Easy Communication</div>
        <div className="WTBdescriptionMobile">
          Make sure you don't miss an important opportunity. Our email system
          helps you get your business started all in one place. Just a couple of
          clicks and your email is setup.
        </div>
        <br />
        <center>
          <img
            width="270"
            height="180"
            src={emailLaptop}
            alt="Editpage website builder screenshot"
          />
        </center>
      </div>
      <div className="websiteToolboxMobile">
        <div className="WTBHeadingMobile">Simple Setup</div>
        <div className="WTBdescriptionMobile">
          We make sure you aren't bothered by unrelated emails. We build our
          email system from the ground up so you can trust what we offer.
        </div>
        <br />
        <center>
          <img
            width="270"
            height="180"
            src={emailCartoon}
            alt="Chef wearing glasses"
          />
        </center>
      </div>
    </div>
  </Mobile>
);

export default MobileResponsive;
