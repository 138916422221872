import constants from '../constants/actionTypes';

var initialState = {
  users: [],
  activeStep: 0,
};

export default (state = initialState, action) => {
  let updated = Object.assign({}, state);

  switch (action.type) {
    case constants.GET_USERS:
      updated['users'] = action.results;
      return updated;

    case constants.UPDATE_USER:
      updated['user'] = action.results;
      return updated;

    default:
      return state;
  }
};
