import React, { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { domainCheck } from '../../../actions/domainActions';
import { toggleCart, toggleCartFilled, totalAmount } from "../../../actions/cartActions";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Fade from '@material-ui/core/Fade';
import CheckoutDomain from "../CheckoutDomain/CheckoutDomain";
import PaymentDomain from '../PaymentDomain';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Circle4 from '../../../images/marketing/featureImages/circle-4.svg';
import GreenCheckMark from '../../../images/domain/check@2x.svg';
import LocalGroceryStore from '../../../images/domain/local-grocery-store@2x.svg';
import '../../responsive/DomainRegistration/DomainRegistration.css';
import './DomainResults.css';
import { nominalTypeHack } from 'prop-types';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 4px',
    display: 'flex',
    backgroundColor: '#f5f6fa',
    alignItems: 'center',
    width: '148.3%',
  },
  input: {
    fontFamily: 'Montserrat',
    fontSize: '22px',
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  success: { backgroundColor: 'purple' },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'rgb(221, 94, 40)' },
  info: { backgroundColor: 'yellow' },
}));

function DomainResults(props) {
  //eslint-disable-next-line
  const classes = useStyles();
  const { domainCheck } = props;
  const [domainName, setDomainName] = React.useState({
    name: props.domainName.name,
  });
  const [domainLoaded, setDomainLoaded] = React.useState(false);
  const [cart, setCart] = React.useState([]);
  const [cartTotal, setCartTotal] = React.useState(0);
  const prevCartLengthRef = useRef(cart.length);
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showCart, setShowCart] = React.useState(false);
  // const [buttonClicked, setButtonClicked] = React.useState({
  //   domainName: '',
  //   key: '',
  // });
  const [checkOut, setCheckOut] = React.useState(false);
  const [payment, setPayment] = React.useState(false);

  const triggerDomainNameDetection = useCallback(() => {
    // Check if domain name has .xxx in it
    let domName = domainName.name;
    let stringArray = domName.split('.');
    if (stringArray.length === 1) {
      setDomainName({ name: domName + '.com' });
      domName = domName + '.com';
    }
    // Send domain check query
    let data = { domain: domainName.name.split('.')[0] };
    domainCheck(data);
    return domName;
  }, [domainName.name, domainCheck]);

  React.useEffect(() => {
    triggerDomainNameDetection();

    // Clean up page so we can show search results
    let elm = document.getElementsByClassName('homePageImage');
    if (elm[0]) {
      elm[0].classList.remove('homePageImage');
    }
    let elms = document.getElementsByClassName('white-font');
    if (elms[0]) {
      let arr = [...elms];
      arr.map((el) => {
        el.classList.remove('white-font');
        el.classList.add('black-font');
        return el;
      });
    }
  }, [triggerDomainNameDetection]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setDomainLoaded(true);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [props.domainsLoaded.isLoaded]);

  React.useEffect(() => {
    const total = () => {
      let totalVal = 0;
      for (let i = 0; i < cart.length; i++) {
        let currentPremiumPrice = Number(cart[i].premiumPrice)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        let updatedItemValue = 0;
        if (cart[i].years > 1) {
          updatedItemValue =
            cart[i].premiumName === 'true'
              ? Number(currentPremiumPrice.replace(',', '')) * cart[i].years
              : cart[i].regularPrice * cart[i].years;
          cart[i].updatePrice = updatedItemValue;
        } else {
          updatedItemValue =
            cart[i].premiumName === 'true'
              ? Number(currentPremiumPrice.replace(',', ''))
              : cart[i].regularPrice;
          cart[i].updatePrice = updatedItemValue;
        }
        totalVal += cart[i].updatePrice;
      }
      setCartTotal(totalVal);
      props.totalAmount(totalVal)
    };
    total();
  }, [cart]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      props.domainsLoaded.isLoaded = false;
      setDomainLoaded(false);
      triggerDomainNameDetection();
    }
  };

  const handleOnChange = (evt) => {
    setDomainName({ name: evt.target.value });
  };

  const triggerShoppingCartAdd = (event, domain) => {
    // setButtonClicked({ domainName: domain.domainName });
    // const action = (key) => (
    //   <React.Fragment>
    //     <AddIcon style={{ paddingLeft: '-30px', fontSize: '64px' }} />
    //     <div style={{ fontWeight: 'bold', display: 'block' }}>
    //       {domain.domainName}
    //     </div>
    //   </React.Fragment>
    // );
    //const key = enqueueSnackbar('', { persist: false, duration: 1, preventDuplicate: true, variant: 'warning', action });

    if (!cart.length) {
      props.toggleCart();
    }

    domain.date = new Date();
    domain.years = 1;
    domain.isDomainAdded = true;
    setCart([...cart, domain]);
    //setTimeout(function(){ closeSnackbar(key); }, 1000);
  };

  const updateDomainYears = (domain, increment) => {
    const currentCartCopy = [...cart];
    const domainToUpdate = currentCartCopy.find(item => item.domainName === domain.domainName);

    if (domainToUpdate) {
      domainToUpdate.years += increment;

      console.log(domainToUpdate.years)
      if (domainToUpdate.years === 0) {
        removeFromCart(domain);
        return;
      }

      setCart(currentCartCopy);
    }
  }

  const addYearToDomain = (el) => {
    updateDomainYears(el, 1);
  }

  const removeYearToDomain = (el) => {
    updateDomainYears(el, -1);
  }

  const removeFromCart = (el) => {
    let hardCopy = [...cart];
    hardCopy = hardCopy.filter(
      (cartItem) => cartItem.domainExt !== el.domainExt
    );
    el.isDomainAdded = false;
    setCart(hardCopy);
  };

  const updateCartItem = (cartItem) => {
    let currentCart = [...cart];
    const updatedCart = currentCart.map((item) => {
      if (item.domainExt === cartItem.domainExt) {
        item.years = cartItem.years;
      }
      return item;
    });
    setCart(updatedCart);
  };

  const updateCartTotal = (newTotal) => {
    setCartTotal(newTotal);
  };

  useEffect(() => {
    if ((prevCartLengthRef.current === 0 && cart.length > 0) || (prevCartLengthRef.current > 0 && cart.length === 0)) {
      props.toggleCartFilled();
    }

    prevCartLengthRef.current = cart.length;
  }, [cart]);

  const cartItems = cart.map((el) => (
    <tr key={el.domainName}>
      <td>{el.domainName}</td>
      <td className="shoppingCart-price">
        <RemoveIcon onClick={() => removeYearToDomain(el)} style={{ cursor: "pointer" }} />
        <span>{el.years}</span>
        <AddIcon onClick={() => addYearToDomain(el)} style={{ cursor: "pointer" }} />
      </td>
      <td>
        <div style={{ position: "relative" }}>
          <b>
            {el.premiumName === 'true'
              ? ` $` +
              Number(el.premiumPrice)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              : ` $` + el.regularPrice}
          </b>
          <CloseIcon className="shoppingCart-removeItem" onClick={() => removeFromCart(el)} />
        </div>
      </td>
    </tr>
  ));

  const showCartItems = () => {
    setShowCart((prev) => !prev);
  };

  const showCheckoutPage = () => {
    setCheckOut(true);
    props.toggleCart();
  };

  const showPaymentPage = () => {
    setPayment(true);
  };

  const recommendedDomains = () => {
    // Remove domain that user searched with unless no domain extention provided.
    let cleanedDomainList = props.domainList
      .sort((a, b) => JSON.parse(b.available) - JSON.parse(a.available))
      .filter((domain) => domain.domainExt !== domainName.name.split('.')[1]);
    const domainExtensionList = cleanedDomainList.map((domain, index) => (
      <div key={index}>
        {index === 0 ? (
          <Grid
            style={{
              flexGrow: 1,
              padding: '10px 0',
            }}
            justify="center"
            container
          >
            <div className="x002-register-domain">
              <div
                className="flex-row"
                justify="center"
                style={{ border: '1px solid #e2e2e2' }}
              >
                <div className="flex-row-1">
                  <div className="flex-col-1">
                    <div className="overview-8" style={{ color: '#171625' }}>
                      {domain.domainName.split('.')[0]}.{domain.domainExt}
                    </div>
                    <div className="frame-2-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Used the ‘.{domain.domainExt}’ extension
                      </div>
                    </div>
                    <div className="frame-3">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <p className="overview-1 inter-normal-mirage-15px">
                        Used less than 10 characters
                      </p>
                    </div>
                  </div>
                  <div className="frame-container">
                    <div className="frame-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Faster load data
                      </div>
                    </div>
                    <div className="frame-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Most affordable plans
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col-2">
                  <div className="overview-container">
                    {/* <div className="overview-9">$35.00</div> */}
                    <div className="overview-10" style={{ color: '#171625' }}>
                      $
                      {domain.premiumName === 'true'
                        ? Number(domain.premiumPrice)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : domain.regularPrice}
                      {domain.premiumName === 'true' ? (
                        <p style={{ color: '#eda800', fontSize: '15px' }}>
                          <LocalPoliceIcon />
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {domain.available === 'true' ?
                    domain.isDomainAdded ? (<Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="hiddenButton-alt"
                    >
                      <div className="button-label-wrapper">
                        <div className="text-3 manrope-bold-alabaster-15px">
                          Added
                        </div>
                      </div>
                    </Button>) : (<Button
                      variant="contained"
                      color="primary"
                      className="hiddenButton-alt"
                      onClick={(e) => triggerShoppingCartAdd(e, domain)}
                    >
                      <div className="button-label-wrapper">
                        <ShoppingCartIcon alt="local_grocery_store" />
                        <div className="text-3 manrope-bold-alabaster-15px">
                          Add to cart
                        </div>
                      </div>
                    </Button>)
                    : (
                      <Button
                        disabled
                        variant="contained"
                        className="disabledButton"
                        onClick={(e) =>
                          triggerShoppingCartAdd(e, searchedDomainDetails[0])
                        }
                      >
                        Not Available
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </Grid>
        ) : (
          <Grid
            style={{
              flexGrow: 1,
              paddingBottom: '10px',
            }}
            justify="center"
            container
          >
            {domain.available === 'true' ? (
              <div className="x002-register-domain">
                <div
                  className="flex-row"
                  style={{ border: '1px solid #e2e2e2' }}
                  justify="center"
                >
                  <div className="flex-row-1">
                    <div className="flex-col-1">
                      <div className="overview-8" style={{ color: '#171625' }}>
                        {domain.domainName.split('.')[0]}.{domain.domainExt}
                      </div>
                      <div className="frame-2-1">
                        <img
                          className="check"
                          src={GreenCheckMark}
                          alt="check"
                        />
                        <div className="overview-1 inter-normal-mirage-15px">
                          Used the ‘.{domain.domainExt}’ extension
                        </div>
                      </div>
                      <div className="frame-3">
                        <img
                          className="check"
                          src={GreenCheckMark}
                          alt="check"
                        />
                        <p className="overview-1 inter-normal-mirage-15px">
                          Used less than 10 characters
                        </p>
                      </div>
                    </div>
                    <div className="frame-container">
                      <div className="frame-1">
                        <img
                          className="check"
                          src={GreenCheckMark}
                          alt="check"
                        />
                        <div className="overview-1 inter-normal-mirage-15px">
                          Faster load data
                        </div>
                      </div>
                      <div className="frame-1">
                        <img
                          className="check"
                          src={GreenCheckMark}
                          alt="check"
                        />
                        <div className="overview-1 inter-normal-mirage-15px">
                          Most affordable plans
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-col-2">
                    <div className="overview-container">
                      {/* <div className="overview-9">$35.00</div> */}
                      <div
                        className="overview-10-alt"
                        style={{ color: '#171625' }}
                      >
                        $
                        {domain.premiumName === 'true'
                          ? Number(domain.premiumPrice)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          : domain.regularPrice}
                        {domain.premiumName === 'true' ? (
                          <div className="premium-layout">
                            <span style={{ color: 'black' }}>Premium</span>
                            <div className="premium-icon-wrapper">
                              <LocalPoliceIcon />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>

                    {domain.isDomainAdded ? (<Button
                      disabled
                      variant="contained"
                      color="primary"
                      className="hiddenButton-alt"
                    >
                      <div className="button-label-wrapper">
                        <div className="text-3 manrope-bold-alabaster-15px">
                          Added
                        </div>
                      </div>
                    </Button>) : (<Button
                      variant="contained"
                      color="primary"
                      className="hiddenButton-alt"
                      onClick={(e) => triggerShoppingCartAdd(e, domain)}
                    >
                      <div className="button-label-wrapper">
                        <ShoppingCartIcon alt="local_grocery_store" />
                        <div className="text-3 manrope-bold-alabaster-15px">
                          Add to cart
                        </div>
                      </div>
                    </Button>)}
                  </div>
                </div>
              </div>
            ) : (
              <div className="x002-register-domain">
                <div
                  className="flex-row-alt"
                  style={{ backgroundColor: '#d3d3d3', borderColor: '#d3d3d3' }}
                  justify="center"
                >
                  <div className="flex-row-1-alt">
                    <div className="flex-col-1-alt">
                      <div className="overlap-group-60">
                        <div className="overview-7">NOT AVAILABLE</div>
                      </div>
                      <div
                        className="overview-8-alt"
                        style={{ color: '#171625' }}
                      >
                        {domain.domainName.split('.')[0]}.{domain.domainExt}
                      </div>
                    </div>
                  </div>
                  <div className="flex-col-2-alt">
                    <div className="overview-container-alt">
                      <div className="overview-10-alt">
                        $
                        {domain.premiumName === 'true'
                          ? Number(domain.premiumPrice)
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          : domain.regularPrice}
                        {domain.premiumName === 'true' ? (
                          <div className="premium-layout">
                            <span style={{ color: 'black' }}>Premium</span>
                            <div className="premium-icon-wrapper">
                              <LocalPoliceIcon />
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Grid>
        )}
      </div>
    ));
    return <div>{domainExtensionList}</div>;
  };

  const domainSearchResult = () => {
    const searchedDomainDetails = props.domainList
      .sort((a, b) => JSON.parse(b.available) - JSON.parse(a.available))
      .filter((domain) => domain.domainExt === domainName.name.split('.')[1]);
    const numberOfAvailableDomains = props.domainList.filter(
      (x) => x.available === 'true'
    ).length;
    console.log('Number of Domain: ', numberOfAvailableDomains);
    return searchedDomainDetails[0] ? (
      <div>
        <div className="domain-results-title-section">
          <div>Available Domains</div>
          <p className="available-domain-results-tag">
            ({numberOfAvailableDomains} Domains found)
          </p>
        </div>
        <Grid
          style={{
            flexGrow: 1,
          }}
          justify="center"
          container
        >
          <div className="x002-register-domain">
            {searchedDomainDetails[0].available === 'true' ? (
              /* Available True */
              <div className="flex-row" justify="center">
                <div className="flex-row-1">
                  <div className="flex-col-1">
                    {searchedDomainDetails[0].available === 'true' ? (
                      <div className="overlap-group-6">
                        <div className="overview-7">MOST RECOMMENDED</div>
                      </div>
                    ) : (
                      <div
                        className="overview-7"
                        style={{
                          height: '39px',
                        }}
                      ></div>
                    )}
                    <div className="overview-8">
                      {searchedDomainDetails[0].domainName.split('.')[0]}.
                      {searchedDomainDetails[0].domainExt}
                    </div>
                    <div className="frame-2-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Used the ‘.{searchedDomainDetails[0].domainExt}’
                        extension
                      </div>
                    </div>
                    <div className="frame-3">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <p className="overview-1 inter-normal-mirage-15px">
                        Used less than 10 characters
                      </p>
                    </div>
                  </div>
                  <div className="frame-container">
                    <div className="frame-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Faster load data
                      </div>
                    </div>
                    <div className="frame-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Most affordable plans
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col-2">
                  <div className="overview-container">
                    <div className="overview-9">$35.00</div>
                    <div className="overview-10">
                      $
                      {searchedDomainDetails[0].premiumName === 'true'
                        ? Number(searchedDomainDetails[0].premiumPrice)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : searchedDomainDetails[0].regularPrice}
                      {searchedDomainDetails[0].premiumName === 'true' ? (
                        <div style={{ color: '#eda800' }}>
                          <LocalPoliceIcon />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {searchedDomainDetails[0].available === 'true' ? domain.isDomainAdded ? (<Button
                    disabled
                    variant="contained"
                    color="primary"
                    className="hiddenButton-alt"
                  >
                    <div className="button-label-wrapper">
                      <div className="text-3 manrope-bold-alabaster-15px">
                        Added
                      </div>
                    </div>
                  </Button>) : (<Button
                    variant="contained"
                    color="primary"
                    className="hiddenButton-alt"
                    onClick={(e) => triggerShoppingCartAdd(e, domain)}
                  >
                    <div className="button-label-wrapper">
                      <ShoppingCartIcon alt="local_grocery_store" />
                      <div className="text-3 manrope-bold-alabaster-15px">
                        Add to cart
                      </div>
                    </div>
                  </Button>)
                    : (
                      <Button
                        disabled
                        variant="contained"
                        className="disabledButton"
                        onClick={(e) =>
                          triggerShoppingCartAdd(e, searchedDomainDetails[0])
                        }
                      >
                        Not Available
                      </Button>
                    )}
                </div>
              </div>
            ) : (
              /* Available False */
              <div
                style={{ borderColor: 'var(--orange)' }}
                className="flex-row"
                justify="center"
              >
                <div className="flex-row-1">
                  <div className="flex-col-1">
                    <div className="overlap-group-60">
                      <div className="overview-7">NOT AVAILABLE</div>
                    </div>
                    <div
                      className="overview-8"
                      style={{ color: 'var(--orange)' }}
                    >
                      {searchedDomainDetails[0].domainName.split('.')[0]}.
                      {searchedDomainDetails[0].domainExt}
                    </div>
                    <div className="frame-2-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Used the ‘.{searchedDomainDetails[0].domainExt}’
                        extension
                      </div>
                    </div>
                    <div className="frame-3">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <p className="overview-1 inter-normal-mirage-15px">
                        Used less than 10 characters
                      </p>
                    </div>
                  </div>
                  <div className="frame-container">
                    <div className="frame-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Faster load data
                      </div>
                    </div>
                    <div className="frame-1">
                      <img className="check" src={GreenCheckMark} alt="check" />
                      <div className="overview-1 inter-normal-mirage-15px">
                        Most affordable plans
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-col-2">
                  <div className="overview-container">
                    <div className="overview-9">$35.00</div>
                    <div
                      className="overview-10"
                      style={{ color: 'var(--orange)' }}
                    >
                      $
                      {searchedDomainDetails[0].premiumName === 'true'
                        ? Number(searchedDomainDetails[0].premiumPrice)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        : searchedDomainDetails[0].regularPrice}
                      {searchedDomainDetails[0].premiumName === 'true' ? (
                        <div style={{ color: '#eda800' }}>
                          <LocalPoliceIcon />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {searchedDomainDetails[0].available === 'true' ? domain.isDomainAdded ? (<Button
                    disabled
                    variant="contained"
                    color="primary"
                    className="hiddenButton-alt"
                  >
                    <div className="button-label-wrapper">
                      <div className="text-3 manrope-bold-alabaster-15px">
                        Added
                      </div>
                    </div>
                  </Button>) : (<Button
                    variant="contained"
                    color="primary"
                    className="hiddenButton-alt"
                    onClick={(e) => triggerShoppingCartAdd(e, domain)}
                  >
                    <div className="button-label-wrapper">
                      <ShoppingCartIcon alt="local_grocery_store" />
                      <div className="text-3 manrope-bold-alabaster-15px">
                        Add to cart
                      </div>
                    </div>
                  </Button>)
                    : (
                      <Button
                        disabled
                        variant="contained"
                        className="disabledButton"
                        onClick={(e) =>
                          triggerShoppingCartAdd(e, searchedDomainDetails[0])
                        }
                      >
                        Not Available
                      </Button>
                    )}
                </div>
              </div>
            )}
          </div>
        </Grid>
      </div>
    ) : (
      <div
        style={{
          fontFamily: 'Montserrat',
          padding: '20px',
          textAlign: 'center',
          fontSize: '22px',
        }}
      >
        Sorry, we don't currently sell .
        <div style={{ color: 'rgb(221, 94, 40)', display: 'inline' }}>
          <b>{domainName.name.split('.')[1]}</b>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          height: '100%',
          width: '100%',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        {checkOut ? (
          payment ? (
            <PaymentDomain cart={cart} cartTotal={cartTotal} />
          ) : (
            <CheckoutDomain
              cart={cart}
              cartTotal={cartTotal}
              setCart={setCart}
              removeFromCart={removeFromCart}
              showPaymentPage={showPaymentPage}
            />
          )
        ) : (
          <div>
            <img
              className="domain-circle-2 img-fluid"
              src={Shape2}
              alt="circle"
            />
            <img
              className="domain-circle-3 img-fluid"
              src={Shape3}
              alt="circle"
            />
            <img
              className="domain-circle-4 img-fluid"
              src={Circle4}
              alt="circle"
            />
            <section className="section-1" id="domain-section-1">
              <div
                className="
                d-flex
                flex-column
                align-items-center
                text-center
                position-relative
              "
              >
                <h1 className="fw-bold section1-head1">Domain search</h1>
                <div className="searchResults d-flex">
                  <div className="input-group">
                    <input
                      onKeyDown={handleKeyDown}
                      onChange={handleOnChange}
                      className="form-control mr-r-5 find-input"
                      placeholder="Search Available Domains"
                      inputProps={{ 'aria-label': 'search available domains' }}
                      defaultValue={
                        domainName.name.split('.').length === 1
                          ? domainName.name + '.com'
                          : domainName.name
                      }
                    />
                    <button
                      className="btn-primary demand-btn-search"
                      type="button"
                      placeholder="Search your desired domain here..."
                      aria-label="Search Domain"
                    >
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </div>
            </section>
            {domainLoaded && props.domainsLoaded.isLoaded ? (
              <div>
                {domainSearchResult()}
                {recommendedDomains()}
              </div>
            ) : (
              <div
                style={{
                  height: '400px',
                  textAlign: 'center',
                  paddingTop: '100px',
                }}
              >
                <CircularProgress color="primary" />
              </div>
            )}
          </div>
        )}
        {props.isCartOpen ? (
          <>
            <div className="editPageShoppingCartBg">
            </div>
            <div className="editPageShoppingCart">
              <h3 className="shoppingCart-title">Cart</h3>
              <CloseIcon onClick={() => props.toggleCart()} className="shoppingCart-close" />

              <div className="shoppingCart-table-scroll">
                <table class="shoppingCart-table">
                  <thead className="shoppingCart-tableHead">
                    <tr>
                      <th>Product</th>
                      <th>Years</th>
                      <th>Total price</th>
                    </tr>
                  </thead>
                  <tbody className="shoppingCart-tableContent">
                    {cartItems}
                  </tbody>
                </table>
              </div>

              <div className="shoppingCart-total">
                Subtotal <span>${cartTotal.toFixed(2)}</span>
              </div>
              <div style={{ width: "100%", marginTop: "30px" }}>
                {checkOut ? (
                  <Button
                    style={{
                      backgroundColor: '#0062FF',
                      color: '#FFFFFF',
                      textTransform: "none",
                      width: "100%",
                      fontFamily: "'Manrope', sans-serif"
                    }}
                    onClick={showPaymentPage}
                    variant="contained"
                    size="large"
                    color="primary"
                    className="ctaButton"
                  >
                    Pay Now
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundColor: '#0062FF',
                      color: '#FFFFFF',
                      textTransform: "none",
                      width: "100%",
                      fontFamily: "'Manrope', sans-serif"
                    }}
                    onClick={showCheckoutPage}
                    variant="contained"
                    size="large"
                    color="primary"
                    className="ctaButton"
                  >
                    Proceed to checkout
                  </Button>
                )}
              </div>

              {/* <span className="shoppingCart-counter">{cart.length}</span>
              <Fade in={showCart}>
                <div className="shoppingCartList">{cartItems}</div>
              </Fade>
              <div
                style={{ color: '#FFF', cursor: 'pointer', width: '150px' }}
                onClick={showCartItems}
              >
                <u>
                  <b>View Cart</b>
                </u>
              </div> */}
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.auth.loggedIn,
    currentUser: state.auth.currentUser,
    domainList: state.domain.domainList,
    domainsLoaded: state.domain.domainsLoaded,
    isCartOpen: state.cart.isCartOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    domainCheck: (data) => {
      dispatch(domainCheck(data));
    },
    toggleCart: () => {
      dispatch(toggleCart());
    },
    toggleCartFilled: () => {
      dispatch(toggleCartFilled())
    },
    totalAmount: (totalVal) => {
      dispatch(totalAmount(totalVal))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DomainResults);
