import React from 'react';
import { useMediaQuery } from 'react-responsive';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Shape from '../../../images/marketing/Shape.svg';
import Shape2 from '../../../images/marketing/Shape2.svg';
import Shape3 from '../../../images/marketing/Shape3.svg';
import Shape5 from '../../../images/marketing/featureImages/Shape5.svg';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1265 });
  return isDesktop ? children : null;
};

const DesktopResponsive = ({ history }) => (
  <Desktop>
    <section className="section-1" style={{ marginBottom: '80px' }}>
      <div
        className="
          d-flex
          flex-column
          align-items-center
          text-center
          position-relative
        "
      >
        <h1 className="fw-bold section1-head1">EditPage Jobs</h1>
        <p className="section1-pera" style={{ color: 'gray' }}>
          Current Job listings
        </p>
      </div>
      <img className="circle-1 img-fluid" src={Shape} alt="circle" />
      <img className="circle-2 img-fluid" src={Shape2} alt="circle" />
      <img className="circle-3 img-fluid" src={Shape3} alt="circle" />
      <img className="circle-4 img-fluid" src={Shape5} alt="circle" />
    </section>
    <div className="featuresBody">
      <div className="jobsPage">
        <Container>
          <Row>
            <Col>
              <div className="jobsDesc">
                At EditPage we pride ourselves on providing an incredible work
                environment. We love to hire talent that brings unique
                prospectives and value to EditPage. We want to have a work
                environment where people feel their idea's are heard and are
                excited about being involved in the growth of EditPage. We
                believe in investing in our team and in their growth.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <div className="currentJobs">
      <div className="currentJobsHeading">Current Jobs at EditPage</div>
      <div className="currentJobsDescription">
        No job openings. Check back again.
      </div>
      <br />
    </div>
  </Desktop>
);

export default DesktopResponsive;
